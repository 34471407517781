import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sendCard from "../../images/icon/send-card.svg";
import replaceCard from "../../images/icon/replace-card.svg";
import giftCard from "../../images/icon/re-gift-card.svg";
import GiftContentItem from "../GiftContentItem/GiftContentItem";
import playstationCard from "../../images/icon/playstation.svg";
import maky from "../../images/icon/maky-icon.svg";
import appleCard from "../../images/icon/apple-icon2.svg";
import playstore from "../../images/icon/playstore.svg";
import PopularCard from "../PopularCard/PopularCard";
import bearImage from "../../images/apple-device-mockup.png";
import EnlightenSection from "../EnlightenSection/EnlightenSection";
import RelevantSearchCard from "../RelevantSearchCard/RelevantSearchCard";
import ThankyouCardItem from "../ThankyouCardItem/ThankyouCardItem";
import PopularGiftIdea from "../PopularGiftIdea/PopularGiftIdea";
import giftIdea1 from "../../images/gift-idea1.jpg";
import giftIdea2 from "../../images/gift-idea2.jpg";
import giftIdea3 from "../../images/gift-idea3.jpg";
import TopBrand from "../TopBrand/TopBrand";
import searchIcon from "../../images/icon/search-icon.svg";
import { useAppContext } from "../../store/appContext";
import API from "../../libs/axios";

const MainContent = () => {
  const { categories } = useAppContext();
  const [searchText, setSearchText] = useState("");
  const [category, setCategory] = useState({});
  const [categoryName, setCategoryName] = useState("");
  const [subCategory, setSubCategory] = useState({});
  const [searchedCards, setSearchedCards] = useState([]);
  const [popularCards, setPopularCards] = useState([]);
  const [search, setSearch] = useState(false);
  useEffect(() => {
    getPopularCards();
  }, []);

  useEffect(() => {
    if (categories && categories.length) {
      setCategory(categories[0]);
      setCategoryName(categories[0].categoryName);
      if (categories[0] && categories[0]?.subCategories?.length) {
        setSubCategory(categories[0].subCategories[0]);
      } else {
        setSubCategory({});
      }
    }
  }, [categories]);

  useEffect(() => {
    (async () => {
      const query = {
        category: category.categoryName,
        search: searchText,
        limit: 4,
      };

      if (category && category.categoryName) {
        query.category = category.categoryName;
      }

      if (subCategory && subCategory.categoryName) {
        query.subCategory = subCategory.categoryName;
      }

      const data = await API.get(`brands`, { params: query });
      setSearchedCards(data.brands);
    })();
  }, [search, category, subCategory]);

  const selectCategory = (e) => {
    if (e.target.value !== "") {
      const cat = categories.find(
        (item) => item.categoryName == e.target.value
      );

      if (cat) {
        setCategory(cat);
        setCategoryName(cat.categoryName);
        if (cat && cat.subCategories && cat.subCategories.length) {
          setSubCategory(cat.subCategories[0]);
        } else {
          setSubCategory({});
        }
      }
    } else {
      setCategoryName("");
      setCategory({});
      setSubCategory({});
    }
  };

  const getPopularCards = async () => {
    const query = { popular: 1, limit: 4 };
    const data = await API.get("brands", { params: query });
    setPopularCards(data.brands);
  };

  const popularGiftIdeasArray = [
    { image: giftIdea1, title: "For Teens" },
    { image: giftIdea2, title: "Beauty" },
    { image: giftIdea3, title: "Food and dining" },
  ];

  const topBrandsArray = [
    { image: playstationCard },
    { image: appleCard },
    { image: maky },
    { image: playstore },
  ];

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setSearch((prev) => !prev);
    }
  };

  return (
    <main className="main-content">
      <section className="gif-features">
        <div className="container">
          <div className="content-wrapper">
            <GiftContentItem
              image={sendCard}
              title={"Send gift cards"}
              text={"Select gift cards from over 200 retailers."}
            />
            <GiftContentItem
              image={replaceCard}
              title={"Replace gift cards"}
              text={"Replace gift cards with what you want."}
            />
            <GiftContentItem
              image={giftCard}
              title={"Re-gift gift cards"}
              text={"Re-gfit a gift card you don’t want to keep."}
            />
          </div>
        </div>
      </section>

      <section className="popular-cards">
        <div className="container">
          <div className="title-header">
            <h2>Popular Gift Cards</h2>
            <div className="dropdown">
              <Link to="/choose-gift-card?cat=Popular">
                <span>See all</span>
              </Link>
            </div>
          </div>
          <div className="row search-category-holder grid-gutters-10">
            {popularCards &&
              popularCards.map((card) => (
                <PopularCard
                  key={card._id}
                  image={card.imageUrls["200w-326ppi"]}
                  brandKey={card.brandKey}
                  brandId={card.id}
                  title={card.brandName}
                />
              ))}
          </div>
        </div>
      </section>

      <section className="enlighten-section">
        <EnlightenSection image={bearImage} />
      </section>

      <section className="search-gift-card">
        <div className="container">
          <div className="title-header">
            <h2>Search Gift Cards</h2>
            <div className="dropdown">
              <Link to="/choose-gift-card">
                <span>See all</span>
              </Link>
            </div>
          </div>

          <div className="search-gift-wrapper">
            <div className="search-form mb-20">
              <div className="row m-0">
                <div className="col-md-7 col-sm-4 p-0">
                  <div className="search-item">
                    <a href="#" className="search-btn">
                      <img src={searchIcon} alt="Icon" />
                    </a>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search for gift cards"
                      value={searchText}
                      onKeyDown={_handleKeyDown}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-3 col-sm-4 p-0">
                  <div className="select-item">
                    <select
                      className="form-control"
                      onChange={(e) => selectCategory(e)}
                      value={categoryName}
                    >
                      <option value="">Select Category</option>
                      {categories &&
                        categories.map((category) => (
                          <option
                            key={category._id}
                            value={category.categoryName}
                          >
                            {category.categoryName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-2 col-sm-2 p-0">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setSearch((prev) => !prev)}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>

            <div className="relevant-searches mb-30">
              <div className="relevant-search-wrapper">
                {category.subCategories &&
                  category.subCategories.map((card, i) => (
                    <RelevantSearchCard
                      handleRelevantClick={() => {
                        setSubCategory(card);
                      }}
                      key={i}
                      title={card.categoryName}
                      selected={
                        subCategory.categoryName === card.categoryName
                          ? true
                          : false
                      }
                    />
                  ))}
                <RelevantSearchCard
                  handleRelevantClick={() => {
                    setSubCategory(null);
                    setCategory({});
                    setCategoryName("");
                  }}
                  title={"See All"}
                  selected={subCategory === null ? true : false}
                />
              </div>
            </div>

            <div className="search-category-section">
              {category ? <h5>{category.categoryName}</h5> : null}
              <div className="row search-category-holder grid-gutters-10">
                {searchedCards && searchedCards.length > 0 ? (
                  <>
                    {searchedCards &&
                      searchedCards.map((card) => (
                        <ThankyouCardItem
                          key={card.brandKey}
                          title={card.brandName}
                          image={card.imageUrls["200w-326ppi"]}
                          brandKey={card.brandKey}
                          brandId={card.id}
                        />
                      ))}
                  </>
                ) : (
                  <div className="banner-caption">
                    <div className="slogan">No gift cards found</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="popular-gift-ideas">
        <div className="container">
          <div className="title-header">
            <h2>Popular Gift Ideas</h2>
          </div>

          <div className="row grid-gutters-9">
            {popularGiftIdeasArray.map((idea, i) => (
              <PopularGiftIdea key={i} image={idea.image} title={idea.title} />
            ))}
          </div>
        </div>
      </section>

      {/* <div className="brand-section">
        <div className="container">
          <div className="title-header">
            <h2>Buy Gift Cards from top brands</h2>
          </div>
          <div className="brand-wrapper">
            {topBrandsArray.map((card, i) => (
              <TopBrand key={i} image={card.image} />
            ))}
          </div>
        </div>
      </div> */}
    </main>
  );
};

export default MainContent;

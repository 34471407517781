import qa from './qa';
import prod from './prod';
import local from './local';

// Default to dev if not set
let config = local;

if (process.env.REACT_APP_STAGE === 'qa') {
  config = qa;
}

if (process.env.REACT_APP_STAGE === 'prod') {
  config = prod;
}
  

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};

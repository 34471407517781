import { Route } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import AppLayout from "./layouts/AppLayout";
import StaticLayout from "./layouts/StaticLayout";
import GiftSent from "./pages/GitfSent/GiftSent";
import Checkout from "./pages/Checkout/Checkout";
import Funds from "./pages/Funds/Funds";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import SendGiftCard from "./pages/SendGiftCard/SendGiftCard";
import JoinShareBear from "./pages/JoinShareBear/JoinShareBear";
import PaymentMethod from "./pages/PaymentMethod/PaymentMethod";
import EmailVerified from "./pages/EmailVerified/EmailVerified";
import ChooseGiftCard from "./pages/ChooseGiftCard/ChooseGiftCard";
import SenderHomePage from "./pages/SenderHomePage/SenderHomepage";
import LoginShareBear from "./pages/LoginShareBear/LoginShareBear";
import UploadContacts from "./pages/UploadContacts/UploadContacts";
import SearchRecipient from "./pages/SearchRecipient/SearchRecipient";
import DetailedGiftCard from "./pages/DetailedGiftCard/DetailedGiftCard";
import RecieverAcceptGc from "./pages/ReceiverAcceptGc/ReceiverAcceptGc";
import RecieverHomepage from "./pages/ReceiverHomepage/ReceiverHomepage";
import RecieverReGiftGc from "./pages/ReceiverReGiftGc/ReceiverReGiftGc";
import RecieverVideoText from "./pages/ReceiverVideoText/ReceiverVideoText";
import RecieverReplaceGc from "./pages/ReceiverReplaceGc/ReceiverReplaceGc";
import DeliveryAndMessage from "./pages/DeliveryAndMessage/DeliveryAndMessage";
import RecieverViewMessage from "./pages/ReceiverViewMessage/ReceiverViewMessage";
import RecieverVideoMessage from "./pages/ReceiverVideoMessage/ReceiverVideoMessage";
import ReceiverLayout from "./layouts/ReceiverLayout";
import ReceiverMainLayout from "./layouts/ReceiverMainLayout";
import AuthToken from "./pages/AuthToken/AuthToken";
import UnauthenticatedRoute from "./UnAuthenticatedRoute";
import AuthenticatedRoute from "./AuthenticatedRoute";
import ResendGiftCard from "./components/ResendGiftCard";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Profile from "./pages/Profile/Profile";
import UpdateProfile from "./pages/UpdateProfile/UpdateProfile";
import AccountBalance from "./pages/AccountBalance/AccountBalance";
import Gifts from "./pages/Gifts/Gifts";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import PrivacyPolicyURL from "./pages/PrivacyPolicy/PrivacyPolicy";

import TermsOfService from "./pages/TermsOfService/TermsOfService";
import Faqs from "./pages/FAQs/FAQs";
import AboutUs from "./pages/AboutUs/AboutUs";
import ContactUS from "./pages/ContactUs/ContactUs";
import SingupFlow from "./components/SingupFlow/SingupFlow";

const routes = [
  {
    path: "/auth",
    component: AuthToken,
    CustomRoute: Route,
  },
  // {
  //   path: "*",
  //   component: SenderHomePage,
  //   layout: MainLayout,
  //   CustomRoute: Route,
  // },
  {
    path: "/home",
    component: SenderHomePage,
    layout: MainLayout,
    CustomRoute: Route,
  },
  {
    path: "/auth/signup",
    component: JoinShareBear,
    layout: AppLayout,
    CustomRoute: UnauthenticatedRoute,
  },
  // {
  //   path: "/auth/signup",
  //   component: SingupFlow,
  //   layout: AppLayout,
  //   CustomRoute: UnauthenticatedRoute,
  // },
  {
    path: "/auth/login",
    component: LoginShareBear,
    layout: AppLayout,
    CustomRoute: UnauthenticatedRoute,
  },
  {
    path: "/auth/forgot-password/:code",
    component: ResetPassword,
    layout: AppLayout,
    CustomRoute: UnauthenticatedRoute,
  },
  {
    path: "/auth/email-verified",
    component: EmailVerified,
    layout: AppLayout,
    CustomRoute: AuthenticatedRoute,
  },
  {
    path: "/profile",
    component: Profile,
    layout: AppLayout,
    CustomRoute: AuthenticatedRoute,
  },
  {
    path: "/update-profile",
    component: UpdateProfile,
    layout: AppLayout,
    CustomRoute: AuthenticatedRoute,
  },
  {
    path: "/balance",
    component: AccountBalance,
    layout: AppLayout,
    CustomRoute: AuthenticatedRoute,
  },
  {
    path: "/gifts",
    component: Gifts,
    layout: AppLayout,
    CustomRoute: AuthenticatedRoute,
  },
  {
    path: "/funds",
    component: Funds,
    layout: AppLayout,
    CustomRoute: AuthenticatedRoute,
  },
  {
    path: "/auth/verify-email/:token/:email",
    component: VerifyEmail,
    layout: AppLayout,
    CustomRoute: Route,
  },
  {
    path: "/payment-method/:orderId",
    component: PaymentMethod,
    layout: AppLayout,
    CustomRoute: Route,
  },
  {
    path: "/gift-sent/:orderId",
    component: GiftSent,
    layout: AppLayout,
    CustomRoute: AuthenticatedRoute,
  },
  {
    path: "/choose-gift-card/:orderId",
    component: ChooseGiftCard,
    layout: AppLayout,
    CustomRoute: Route,
  },
  {
    path: "/choose-gift-card",
    component: ChooseGiftCard,
    layout: AppLayout,
    CustomRoute: Route,
  },
  {
    path: "/checkout/:orderId",
    component: Checkout,
    layout: AppLayout,
    CustomRoute: Route,
  },

  {
    path: "/delivery-message/:orderId",
    component: DeliveryAndMessage,
    layout: AppLayout,
    CustomRoute: Route,
  },
  {
    path: "/detailed-gift-card/:title/:brandKey/:brandId/:orderId",
    component: DetailedGiftCard,
    layout: AppLayout,
    CustomRoute: Route,
  },
  {
    path: "/detailed-gift-card/:title/:brandKey/:brandId",
    component: DetailedGiftCard,
    layout: AppLayout,
    CustomRoute: Route,
  },
  {
    path: "/search-recipient/:orderId",
    component: SearchRecipient,
    layout: AppLayout,
    CustomRoute: Route,
  },
  {
    path: "/send-gift-card/:orderId",
    component: SendGiftCard,
    layout: AppLayout,
    CustomRoute: Route,
  },
  {
    path: "/send-gift-card",
    component: SendGiftCard,
    layout: AppLayout,
    CustomRoute: Route,
  },
  {
    path: "/upload-contacts/:orderId",
    component: UploadContacts,
    layout: AppLayout,
    CustomRoute: Route,
  },
  {
    path: "/receiver-accept-gc/:orderId",
    component: RecieverAcceptGc,
    layout: ReceiverLayout,
    CustomRoute: Route,
  },
  {
    path: "/receiver-view-message",
    component: RecieverViewMessage,
    layout: ReceiverLayout,
    CustomRoute: Route,
  },
  {
    path: "/receiver-video-text-message/:orderId",
    component: RecieverVideoText,
    layout: ReceiverLayout,
    CustomRoute: Route,
  },
  {
    path: "/receiver-video-message",
    component: RecieverVideoMessage,
    layout: ReceiverLayout,
    CustomRoute: Route,
  },
  {
    path: "/receiver-regift-gc/:orderId",
    component: RecieverReGiftGc,
    layout: ReceiverLayout,
    CustomRoute: Route,
  },
  {
    path: "/receiver-resend-gift-gc/:orderId",
    component: ResendGiftCard,
    layout: ReceiverLayout,
    CustomRoute: AuthenticatedRoute,
  },
  {
    path: `/receiver-replace-gc/:orderId`,
    component: RecieverReplaceGc,
    layout: ReceiverLayout,
    CustomRoute: Route,
  },
  {
    path: "/receiver/:orderId",
    component: RecieverHomepage,
    layout: ReceiverMainLayout,
    CustomRoute: Route,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    layout: StaticLayout,
    CustomRoute: Route,
  },

  {
    path: "/privacypolicy",
    component: PrivacyPolicyURL,
    layout: StaticLayout,
    CustomRoute: Route,
  },
  {
    path: "/terms-of-service",
    component: TermsOfService,
    layout: StaticLayout,
    CustomRoute: Route,
  },
  {
    path: "/faqs",
    component: Faqs,
    layout: StaticLayout,
    CustomRoute: Route,
  },
  {
    path: "/about-us",
    component: AboutUs,
    layout: StaticLayout,
    CustomRoute: Route,
  },
  {
    path: "/contact-us",
    component: ContactUS,
    layout: StaticLayout,
    CustomRoute: Route,
  },
  {
    path: "/",
    component: SenderHomePage,
    layout: MainLayout,
    CustomRoute: Route,
  },
];

export default routes;

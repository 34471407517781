import React from "react";
import { useHistory } from "react-router-dom";

const PopularGiftIdea = ({ image, title }) => {
  const history = useHistory();

  return (
    <div className="col-md-4 clickable">
      <div className="gift-ideas-holder">
        <div
          className="figure-item"
          style={{ backgroundImage: `url(${image})` }}
          onClick={() => history.push(`/choose-gift-card?cat=${title}`)}
        ></div>
        <div className="figure-caption">
          <h2>{title}</h2>
        </div>
      </div>
    </div>
  );
};

export default PopularGiftIdea;

import React from "react";
import { useHistory } from "react-router-dom";
import Confetti from "../Confetti/Confetti";

const Banner = () => {
  const history = useHistory();
  return (
    <section className="intro-banner">
      <Confetti />
      <div className="banner-section">
        <div className="content-holder">
          <div
            className="image-item"
            // style="background-image: url('images/intro-banner.jpg');"
          ></div>
          <div className="banner-caption">
            <h1>
              Show your appreciation with <br /> messages and gifts of joy
            </h1>
            <div className="slogan">Gifting made simple, fun and global.</div>
            <button
              className="btn btn-primary"
              onClick={() => history.push("/choose-gift-card")}
            >
              Send a gift
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;

import React from "react";
import VerifyEmailMain from "../../components/VerifyEmailMain/VerifyEmailMain";
import { useParams } from "react-router-dom";

const VerifyEmail = () => {
  const { token, email } = useParams();

  return <VerifyEmailMain email={email} token={token} />;
};

export default VerifyEmail;

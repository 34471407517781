import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import verifiedEmail from "../../images/icon/verified-email.svg";
import verifyEmail from "../../images/icon/verify-email.svg";
import API from "../../libs/axios";
import { useAppContext } from "../../store/appContext";
import GoBack from "../GoBack/GoBack";
import HavingProblems from "../HavingProblems/HavingProblems";

const VerifyEmailMain = ({ token, email }) => {
    const {
        userHasAuthenticated,
        setUser,
        setToken
    } = useAppContext();
  const history = useHistory();

    const [verified, setVerified] = useState(null);
    const [alreadyVerified, setAlreadVerified] = useState(false);

    useEffect(async () => {
        const data = await API.get(`auth/verification-check/${token}`);

        if (data.alreadyVerified) {
            setAlreadVerified(true);
        }
    }, []);

    const verifyToken = async () => {
        const verification = await API.get(`auth/verify-email/${token}`);

        if (verification?.user) {
            localStorage.setItem("token", token);
            userHasAuthenticated(true);
            setUser(verification.user)
            setToken(verification.token);
            userHasAuthenticated(true);
            setVerified(true);
            toaster("success", "Thanks! Your email is verified!");
            history.push("/funds");
        } else {
            setVerified(false);
        }
    };
    return (
        <main className="main-content">
            <div className="container card-flex">
                <div className="pt-60 pt-30-sm pb-60 pb-30-sm flex-grow-1">
                    <div className="card-content-wrapper card-content-box">
                        <div className="card-item">
                            <div className="info-text text-right mb-50 max-w-370">
                                <HavingProblems />
                            </div>

                            <div className="card-block-wrapper popup-box max-w-370 mb-20">
                                <div className="icon-holder">
                                    <img
                                        src={alreadyVerified ? verifiedEmail : verifyEmail}
                                        alt="Icon"
                                        className="img-fluid"
                                    />
                                </div>
                                {alreadyVerified ? (
                                    <>
                                        <div className="card-block-title mb-30">
                                            <h1 className="text-center mb-10">Already Verified</h1>
                                            <p>
                                                Your email account <a href="#">{email}</a> is already
                                                verified. Click Login to send gifts.
                                            </p>
                                        </div>

                                        <div className="btn-section align-items-center">
                                            <GoBack />

                                            <Link to="/auth/login">
                                                <button className="btn btn-primary">Login</button>
                                            </Link>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="card-block-title mb-30">
                                            <h1 className="text-center mb-10">Verify your email</h1>
                                            <p>
                                                You have entered <a href="#">{email}</a> as the email
                                                address of your account. Please verify this email by
                                                clicking the button below.
                                            </p>
                                        </div>

                                        <div className="btn-section align-items-center">
                                            <GoBack />

                                            <button className="btn btn-primary" onClick={verifyToken}>
                                                Click to verify
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {verified === true ? <Redirect to="/auth/email-verified" /> : null}
            {/* {verified === true ? <Redirect to="/funds" /> : null} */}

        </main>
    );
};

export default VerifyEmailMain;

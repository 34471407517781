import React, { useState, useEffect } from "react";
import userIcon from "../../images/icon/user-icon.svg";
import emailIcon from "../../images/icon/email-icon.svg";
import appleCard from "../../images/popular-cards/apple-card.png";
import playButton from "../../images/play-btn.png";
import redoButton from "../../images/redo-btn.png";
import { Link, useHistory, useParams } from "react-router-dom";
import { useAppContext } from "../../store/appContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import API from "../../libs/axios";
import { onError } from "../../libs/errorLib";
import Input from "../Input/Input";
import DatePicker from "react-datepicker";
import GoBack from "../GoBack/GoBack";
import { toaster } from "../../libs/utils";

const CheckoutMainContent = () => {
  const { orderId } = useParams();
  const history = useHistory();
  const { isLoading, setIsLoading, me } = useAppContext();
  const [message, setMessage] = useState("");
  const [order, setOrder] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(new Date());

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
    if (orderId) {
      getOrder();
    }
  }, []);

  const getOrder = async () => {
    const order = await API.get(`orders/${orderId}`);
    if (order) {
      setOrder(order);
      setDeliveryDate(new Date(order.deliveryDate));
      setMessage(order.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      recipient_name: order && order !== null ? order.recipient.firstName : "",
      recipient_email:"",
    },
    enableReinitialize: true,

    validationSchema: Yup.object().shape(
      me === "mee"
        ? {
            recipient_name: Yup.string().required("Name is required"),
          }
        : {
            recipient_name: Yup.string().required("Name is required"),
            recipient_email: Yup.string()
              .email("Email is not valid")
              .required("Email is required"),
          }
    ),

    onSubmit: async (values, {resetForm}) => {
      setIsLoading(true);
      try {
        await API.put(`orders/${orderId}`, {
          ...order,
          recipient:
            me === "mee"
              ? {
                  firstName: values.recipient_name,
                }
              : {
                  firstName: values.recipient_name,
                  email: values.recipient_email,
                },
          message: message,
          status: me == "mee" ? "FINALIZED" : "SENT",
          deliveryDate: deliveryDate,
        });
        toaster("success", "Order sent successfully!");
        history.push("/gifts");
      } catch (e) {
        onError(e);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <main className="main-content">
      <section className="gift-card-section">
        <div className="container">
          <div className="card-content-wrapper max-w-740 checkout-section">
            <div className="card-item">
              <div className="delivery-checkout-form mt-40">
                <div className="gc-detail-title pb-20 mb-20 b-border">
                  <h3 className="mb-5">Checkout</h3>
                  <p className="mb-0">
                    Confirm your recipient, gift card and message.
                  </p>
                </div>
                <form noValidate onSubmit={formik.handleSubmit}>
                  <div className="form-item pb-30 mb-30 b-border">
                    <div className="form-group">
                      <label className="title-info">Recipient’s name</label>
                      <div className="input-item bg-color">
                        <Input
                          type="text"
                          name="recipient_name"
                          id="recipient_name"
                          placeholder="Sameer Ali"
                          formik={formik}
                          {...formik.getFieldProps("recipient_name")}
                        />
                        <img src={userIcon} alt="Username Icon" />
                      </div>
                    </div>
                    {me !== "mee" && (
                      <div className="form-group">
                        <label className="title-info">Recipient’s email</label>
                        <div className="input-item bg-color">
                          <Input
                            type="text"
                            name="recipient_email"
                            id="recipient_email"
                            placeholder="Recipient Email"
                            formik={formik}
                            {...formik.getFieldProps("recipient_email")}
                          />
                          <img src={emailIcon} alt="email Icon" />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="form-item apply-giftcard pt-10 pb-30 mb-30 b-border">
                    <div className="content-holder">
                      <div className="image-holder">
                        <img
                          src={
                            order &&
                            order !== null &&
                            order.brandId.imageUrls["1200w-326ppi"]
                          }
                          alt="Card Item"
                          className="img-fluid"
                        />
                      </div>
                      <div className="content-desc">
                        <div className="btn-item">
                          <Link to={`/choose-gift-card/${orderId}`}>
                            Change
                          </Link>
                        </div>
                        <h4>
                          {order &&
                            order !== null &&
                            order.brandId &&
                            order.brandId.brandName}
                        </h4>
                        <div className="price-value">
                          ${order && order !== null && order.amount}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-item pb-30 mb-30 b-border date-picker">
                    <div className="form-group bg-color">
                      <div className="input-item bg-color">
                        <label className="title-info">Delivery Date</label>
                        <DatePicker
                          selected={deliveryDate}
                          minDate={new Date()}
                          disabled={true}
                          onChange={(date) => setDeliveryDate(date)}
                        />
                        <img src={emailIcon} alt="email Icon" />
                      </div>
                    </div>
                  </div>
                  {order && order.message && (
                    <div className="form-item pb-30 mb-30 b-border">
                      <div className="form-group bg-color">
                        <label className="title-info">Message</label>
                        <textarea
                          id="message"
                          className="form-control"
                          maxLength="250"
                          placeholder=""
                          rows="5"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          disabled
                        ></textarea>
                        <div className="info-label-text text-right py-1">
                          {message?.length || 0} / 250
                        </div>
                      </div>
                    </div>
                  )}
                  {order && order.video && (
                    <div className="form-item pb-30 mb-30 b-border">
                      <div className="form-group bg-color">
                        <label className="title-info">Video Message</label>
                        <div className="bg-color-grey video-message-wrapper">
                          <div className="btn-wrapper">
                            <div className="action-btn play-video">
                              {/* <img src={playButton} alt="Play Video Button" /> */}
                              {/* <label className="title-info">Play Video</label> */}
                              <video
                                controls
                                className="title-info"
                                src={order.video}
                              />
                            </div>
                            {/* <div className="action-btn redo-video">
                            <img src={redoButton} alt="Redo Video Button" />
                            <label className="title-info"></label>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="apply-conditions mb-30">
                    <h5>Apple Terms and Conditions</h5>
                    <p>
                      By clicking Place order, you have read and you agree to
                      the above terms and conditions.
                    </p>
                  </div>

                  <div className="btn-section align-items-center">
                    <GoBack />
                    <button type="submit" className="btn btn-primary">
                      Place Order
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CheckoutMainContent;

import React from "react";
import Header from "../../components/Header/Header";
import LoginMainComponent from "../../components/Login/LoginMainContent";

const LoginShareBear = () => {
  return (
    <>
      <Header />
      <LoginMainComponent />
    </>
  );
};

export default LoginShareBear;

import React from "react";
import '../scss/atomicStructure/atomic.scss'

const SecondaryTitle = (props) => {

  return (
    <p className="templateSecondaryTitle mb-0">
      {props.text}
    </p>
  );
};

export default SecondaryTitle;

import React from "react";
import Footer from "../components/Footer/Footer";
import HomePageHeader from "../components/Header/HomePageHeader";

const MainLayout = ({ children }) => {
  return (
    <>
      <HomePageHeader />
      {children}
      <Footer />
    </>
  );
};

export default MainLayout;

import React from "react";
import Banner from "../../components/Banner/Banner";
import MainContent from "../../components/MainContent/MainContent";

const SenderHomePage = () => {
  return (
    <>
      <Banner />
      <MainContent />
    </>
  );
};

export default SenderHomePage;

import React from "react";
import CheckoutMainContent from "../../components/CheckoutMainContent/CheckoutMainContent";
import Footer from "../../components/Footer/Footer";

const Checkout = () => {
  return (
    <>
      <CheckoutMainContent />
      <Footer />
    </>
  );
};

export default Checkout;

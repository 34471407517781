import React from "react";
import { useHistory } from "react-router-dom";

const ThankyouCardItem = ({ image, title, brandId, brandKey }) => {
  const history = useHistory();
  const navigate = () => {
    history.push(`/detailed-gift-card/${title}/${brandKey}/${brandId}`);
  };
  return (
    <div className="col-sm-3 clickable" onClick={navigate}>
      <div className="card-item">
        <div className="icon-holder">
          <img src={image} alt="Popular Card" className="img-fluid" />
        </div>
        <div className="card-title">{title}</div>
      </div>
    </div>
  );
};

export default ThankyouCardItem;

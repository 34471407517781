import React from "react";
import { Link, useHistory } from "react-router-dom";

const GoBack = ({ url, label= "Go Back" }) => {
  const history = useHistory();
  const SvgBack = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="13"
        viewBox="0 0 8 13"
        fill="none"
      >
        <path
          d="M3.3 6.5L7.3 2.5C7.8 2.1 7.8 1.4 7.3 1 6.9 0.5 6.2 0.5 5.8 1L1 5.8C0.5 6.2 0.5 6.8 1 7.2L5.8 12C6.2 12.5 6.9 12.5 7.3 12 7.8 11.6 7.8 10.9 7.3 10.5L3.3 6.5Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  return (
    <>
      <div className="btn-link mb-0">
        {url && (
          <Link className="back-btn" to={url}>
            <SvgBack />
            <span>{label}</span>
          </Link>
        )}
        {!url && (
          <a
            className="back-btn"
            href={null}
            onClick={(_) => history.goBack()}
          >
            <SvgBack />
            <span>Go back</span>
          </a>
        )}
      </div>
    </>
  );
};

export default GoBack;

import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import API from "../../libs/axios";
import MarkupParagraph from "../MarkupParagraph";
import { useAppContext } from "../../store/appContext";
import Loader from "../Loader/Loader";

const ReceiverReGiftGcMain = () => {
  const history = useHistory();
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const { user, isAuthenticated } = useAppContext();

  useEffect(() => {
    if (orderId) {
      getOrder();
    }
  }, []);

  const getOrder = async () => {
    const order = await API.get(`orders/${orderId}`);
    if (order) {
      setOrder(order);
    }
  };

  return (
    <main className="main-content">
      <div className="container">
        <div className="card-content-wrapper giftcard-detail max-w-1070">
          <div className="card-item mt-20 mb-20">
            <div className="reciever-content-wrapper pt-50 pb-50">
              {!order ? (
                <Loader />
              ) : (
                <div className="card-holder">
                  <div className="dropdown mb-30">
                    <a
                      className="dropdown-toggle"
                      data-toggle="collapse"
                      href="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <span>{order?.sender.firstName}’s message</span>
                    </a>
                    <div className="collapse pt-16" id="collapseExample">
                      <p>{order?.message}</p>
                    </div>
                  </div>

                  <div className="title-block mb-50">
                    <h2 className="mb-35">{order?.sender.firstName}’s gift!</h2>
                    <h5 className="mb-10">
                      ${order?.amount} {order?.brandId.brandName}
                    </h5>
                    <p className="mb-0">
                      {order?.sender.firstName} has sent you a ${order?.amount}{" "}
                      {order?.brandId.brandName}
                    </p>
                  </div>

                  <div className="category-detail-item mb-50">
                    <div className="content-holder">
                      <div className="image-holder">
                        <img
                          src={order?.brandId.imageUrls["1200w-326ppi"]}
                          alt="card item"
                          className="img-fluid"
                          width={220}
                        />
                      </div>
                      <div className="content-desc">
                        <MarkupParagraph
                          text={order?.brandId.description}
                          showMore={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="text-block">
                    <h5 className="mb-0">
                      What would you like to do with the gift card?
                    </h5>
                    <p className="mb-25">
                      Keep it for your self, replace it with another card or
                      share to another bear!
                    </p>

                    <div className="radio-btn-wrapper">
                      <div className="form-check">
                        <label className="form-check-label">
                          <Link to={`/receiver-accept-gc/${orderId}`}>
                            <input
                              type="radio"
                              className="form-check-input"
                              name="optradio"
                            />
                            <span>Keep gift card</span>
                          </Link>
                        </label>
                      </div>
                      <div className="form-check">
                        <label className="form-check-label">
                          <Link to={`/receiver-replace-gc/${orderId}`}>
                            <input
                              type="radio"
                              className="form-check-input"
                              name="optradio"
                            />
                            <span>Replace gift card</span>
                          </Link>
                        </label>
                      </div>
                      <div className="form-check disabled">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="optradio"
                            checked
                          />
                          <span>Forward gift card</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="text-block mb-40">
                    <h5 className="mb-0">
                      Are you sure want to accept the GiftCard?
                    </h5>
                    <p>
                      You can replace this{" "}
                      <strong>
                        ${order?.amount} {order?.brandId.brandName}{" "}
                      </strong>{" "}
                      Card by selecting a gift card you would like to switch
                      this with.
                    </p>
                  </div>

                  <button
                    className="btn btn-rounded btn-primary"
                    onClick={() =>
                      history.push(
                        isAuthenticated
                          ? `/receiver-resend-gift-gc/${orderId}`
                          : `/auth/login?redirect=/receiver-resend-gift-gc/${orderId}`
                      )
                    }
                  >
                    Re-gift gift card
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ReceiverReGiftGcMain;

import React from "react";
import HavingProblems from "../../components/HavingProblems/HavingProblems";
import verifiedEmail from "../../images/icon/verified-email.svg";
import { Link } from "react-router-dom";

const WelcomeScreen = ({ setResend, setShowWelcomeScreen }) => {
  return (
    <main className="main-content">
      <div className="container card-flex">
        <div className="pt-60 pt-30-sm pb-60 pb-30-sm flex-grow-1">
          <div className="card-content-wrapper card-content-box">
            <div className="card-item">
              <div className="info-text text-right mb-50 max-w-370">
                <HavingProblems />
              </div>

              <div className="card-block-wrapper max-w-370 mb-20">
                <div className="icon-holder">
                  <img src={verifiedEmail} alt="Icon" className="img-fluid" />
                </div>
                <div className="card-block-title mb-30">
                  <h1 className="text-center mb-10">Welcome to ShareBear!</h1>
                  <p className="text-center">
                    You’re ready to start sharing. Please check your email to
                    verify account
                  </p>
                </div>
                <div className="text-center">
                  <a
                    onClick={() => {
                      setResend(true);
                      setShowWelcomeScreen(false);
                    }}
                  >
                    <span>Click here to resend verification e-mail</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default WelcomeScreen;

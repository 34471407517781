import React from "react";

const ReceiverViewMessageMainContent = () => {
  return (
    <main className="main-content">
      <div className="container">
        <div className="card-content-wrapper">
          <div className="card-item mt-20 pb-20">
            <div className="reciever-content-wrapper">
              <div className="card-holder">
                <h2 className="mb-10">Sam’s message</h2>
                <div className="bg-color-grey text-box">
                  <p>
                    This is where your message you posted is going to appear.
                    You can write up to 250 characters as the limit. It can be
                    shared either as an email, or a text sms message directly to
                    a user’s cell number.
                  </p>
                </div>
                <div className="btn-section justify-content-end">
                  <button className="btn btn-rounded btn-primary">
                    View Gift card
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ReceiverViewMessageMainContent;

import React, { useEffect, useState } from "react";
import SearchedCard from "../SearchedCard/SearchedCard";
import searchIcon from "../../images/icon/search-icon.svg";
import { Link } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";
import MarkupParagraph from "../MarkupParagraph";
import API from "../../libs/axios";
import { useAppContext } from "../../store/appContext";
import LoaderButton from "../LoaderButton/LoaderButton";
import RelevantSearchCard from "../RelevantSearchCard/RelevantSearchCard";
import { onError } from "../../libs/errorLib";
import Loader from "../Loader/Loader";

const ReceiverReplaceGcMain = () => {
  const history = useHistory();
  const { orderId } = useParams();
  const {
    setIsLoading,
    isAuthenticated,
    isLoading,
    categories,
    setRedirect,
  } = useAppContext();

  const [order, setOrder] = useState(null);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState(false);
  const [category, setCategory] = useState({});
  const [categoryName, setCategoryName] = useState("");
  const [subCategory, setSubCategory] = useState({});

  useEffect(() => {
    if (orderId) {
      getOrder();
    }
  }, []);

  useEffect(() => {
    if (categories && categories.length) {
      setCategory(categories[0]);
      setCategoryName(categories[0].categoryName);
      if (categories[0] && categories[0]?.subCategories?.length) {
        setSubCategory(categories[0].subCategories[0]);
      } else {
        setSubCategory({});
      }
    }
  }, [categories]);

  useEffect(() => {
    (async () => {
      const query = {
        category: category.categoryName,
        search: searchText,
        limit: 6,
        amount: order?.amount,
      };

      if (category && category.categoryName) {
        query.category = category.categoryName;
      }

      if (subCategory && subCategory.categoryName) {
        query.subCategory = subCategory.categoryName;
      }
      setIsLoading(true);
      const data = await API.get(`brands`, { params: query });
      setBrands(data.brands);
      setIsLoading(false);
    })();
  }, [search, category, subCategory]);

  const selectCategory = (e) => {
    if (e.target.value !== "") {
      const cat = categories.find(
        (item) => item.categoryName == e.target.value
      );

      if (cat) {
        setCategory(cat);
        setCategoryName(cat.categoryName);
        if (cat && cat.subCategories && cat.subCategories.length) {
          setSubCategory(cat.subCategories[0]);
        } else {
          setSubCategory({});
        }
      }
    } else {
      setCategoryName("");
      setCategory({});
      setSubCategory({});
    }
  };

  const getBrands = async (order) => {
    const query = {
      limit: 6,
      amount: order.amount,
    };

    const brands = await API.get(`brands`, { params: query });
    setBrands(brands.brands);
  };

  const getOrder = async () => {
    const order = await API.get(`orders/${orderId}`);
    if (order) {
      setOrder(order);
      getBrands(order);
    }
  };
  
  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setSearch((prev) => !prev);
    }
  };

  const updateOrder = async () => {
    if (!isAuthenticated) {
      history.push(`/auth/login?redirect=/receiver-replace-gc/${orderId}`);
    }
    if (order.status === "FINALIZED") {
        return onError({ message: "This gift card transaction is already completed" });
    }

    let item = null;
    if (selectedBrand && selectedBrand.items[0].valueType === "FIXED_VALUE") {
      const index = selectedBrand.items.findIndex(
        (item) => item.faceValue === order.amount
      );
      if (index !== -1) {
        item = selectedBrand.items[index];
      }
    } else {
      item = selectedBrand.items[0];
    }

    try {
      setIsLoading(true);
      const response = await API.put(`orders/${orderId}`, {
        status: "FINALIZED",
        utid: item.utid,
        brandId: selectedBrand._id,
      });

      if (response) {
        setRedirect();
        history.push(`/gift-sent/${orderId}?receiver=${true}`);
      }
    } catch (err) {
      onError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className="main-content">
      <div className="container">
        <div className="card-content-wrapper giftcard-detail max-w-1070">
          <div className="card-item mt-20 mb-20">
            <div className="reciever-content-wrapper pt-50 pb-50">
              {!order ? (
                <Loader />
              ) : (
                <div className="card-holder">
                  <div className="dropdown mb-30">
                    <a
                      className="dropdown-toggle"
                      data-toggle="collapse"
                      href="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <span>{order?.sender.firstName}’s message</span>
                    </a>
                    <div className="collapse pt-16" id="collapseExample">
                      <p>{order?.message}</p>
                    </div>
                  </div>

                  <div className="title-block mb-50">
                    <h2 className="mb-35">{order?.sender.firstName}’s gift!</h2>
                    <h5 className="mb-10">
                      ${order?.amount} {order?.brandId.brandName}
                    </h5>
                    <p className="mb-0">
                      {order?.sender.firstName} has sent you a ${order?.amount}{" "}
                      {order?.brandId.brandName}
                    </p>
                  </div>

                  <div className="category-detail-item mb-50">
                    <div className="content-holder">
                      <div className="image-holder">
                        <img
                          src={order?.brandId.imageUrls["1200w-326ppi"]}
                          alt="card item"
                          className="img-fluid"
                          width={220}
                        />
                      </div>
                      <div className="content-desc">
                        <MarkupParagraph
                          text={order?.brandId.description}
                          showMore={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="text-block mb-40 b-border">
                    <h5 className="mb-0">
                      What would you like to do with the gift card?
                    </h5>
                    <p className="mb-25">
                      Keep it for your self, replace it with another card or
                      share to another bear!
                    </p>

                    <div className="radio-btn-wrapper">
                      <div className="form-check">
                        <label className="form-check-label">
                          <Link to={`/receiver-accept-gc/${orderId}`}>
                            <input
                              type="radio"
                              className="form-check-input"
                              name="optradio"
                            />
                            <span>Keep gift card</span>
                          </Link>
                        </label>
                      </div>
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="optradio"
                            checked
                          />
                          <span>Replace gift card</span>
                        </label>
                      </div>
                      <div className="form-check disabled">
                        <label className="form-check-label">
                          <Link to={`/receiver-regift-gc/${orderId}`}>
                            <input
                              type="radio"
                              className="form-check-input"
                              name="optradio"
                            />
                            <span>Forward gift card</span>
                          </Link>
                        </label>
                      </div>
                    </div>
                  </div>

                  <h2 className="mt-40 mb-40">Replace gift card</h2>

                  <div className="search-gift-card search-gift-wrapper no-padding">
                    <div className="search-form mb-20">
                      <div className="row m-0">
                        <div className="col-md-7 col-sm-6 p-0">
                          <div className="search-item">
                            <a href="#" className="search-btn">
                              <img src={searchIcon} alt="Icon" />
                            </a>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search for gift cards"
                              value={searchText}
                              onKeyDown={_handleKeyDown}
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-4 p-0">
                          <div className="select-item">
                            <select
                              className="form-control"
                              onChange={(e) => selectCategory(e)}
                              value={categoryName}
                            >
                              <option value="">Select Category</option>
                              {categories &&
                                categories.map((category) => (
                                  <option
                                    key={category._id}
                                    value={category.categoryName}
                                  >
                                    {category.categoryName}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-2 col-sm-2 p-0">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setSearch((prev) => !prev)}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="relevant-searches mb-30">
                      <div className="relevant-search-wrapper">
                        {category.subCategories &&
                          category.subCategories.map((card, i) => (
                            <RelevantSearchCard
                              handleRelevantClick={() => {
                                setSubCategory(card);
                              }}
                              key={i}
                              title={card.categoryName}
                              selected={
                                subCategory.categoryName === card.categoryName
                                  ? true
                                  : false
                              }
                            />
                          ))}
                        <RelevantSearchCard
                          handleRelevantClick={() => {
                            setSubCategory(null);
                            setCategory({});
                            setCategoryName("");
                          }}
                          title={"See All"}
                          selected={subCategory === null ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="category-searched-list">
                    <div className="row">
                      {brands &&
                        brands.map((card) => (
                          <SearchedCard
                            image={card.imageUrls["1200w-326ppi"]}
                            title={card.brandName}
                            key={card.brandKey}
                            brandKey={card.brandKey}
                            text={card.shortDescription}
                            orderId={orderId}
                            brandId={card.id}
                            selected={selectedBrand}
                            recevier={true}
                            setBrand={() => setSelectedBrand(card)}
                          />
                        ))}
                    </div>
                  </div>

                  <div className="text-block mb-40">
                    <h5 className="mb-0">
                      Are you sure want to accept the GiftCard?
                    </h5>
                    <p>
                      You can replace this{" "}
                      <strong>
                        ${order?.amount} {order?.brandId.brandName}
                      </strong>{" "}
                      Card by selecting a gift card you would like to switch
                      this with.
                    </p>
                  </div>
                  <LoaderButton
                    className="btn btn-primary"
                    //   type="submit"
                    buttonText="Replace gift card"
                    loadingText="loading"
                    isLoading={isLoading}
                    onClick={updateOrder}
                  />
                  {/* <button
                  className="btn btn-rounded btn-primary"
                  onClick={updateOrder}
                >
                  Replace gift card
                </button> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ReceiverReplaceGcMain;

import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import emailIcon from "../../images/icon/email-icon.svg";
import passwordIcon from "../../images/icon/password-icon.svg";
import { useAppContext } from "../../store/appContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/Input";
import API from "../../libs/axios";
import { onError } from "../../libs/errorLib";
import LoaderButton from "../LoaderButton/LoaderButton";
import GoBack from "../GoBack/GoBack";
import queryString from "query-string";
import config from "../../config";
import { toaster } from "../../libs/utils";

const LoginMainComponent = ({ location, history }) => {
  let params = queryString.parse(location.search);
  const {
    userHasAuthenticated,
    setUser,
    setToken,
    setRedirect,
  } = useAppContext();
  const { isLoading, setIsLoading } = useAppContext();
  const [forgotPass, setForgotPass] = useState(false);
  const [type, setType] = useState(false);

  useEffect(() => {
    setRedirect(params.redirect);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Email is not valid")
        .required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .label("Password")
        .min(6),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const user = await API.post(`auth/login`, values);
        if (user && !user.error) {
          setUser(user.user);
          setToken(user.token);
          userHasAuthenticated(true);
          toaster("info", "You signed in successfully into sharebear");
          if (params && params.redirect) {
            history.push(params.redirect);
          }
        }
        if (user.error) {
          const emailToVerify = values.email;
          const data = await API.post(`auth/resend-email`, { emailToVerify });
          if (data.message) {
            toaster("info", data.message);
          }
        }
      } catch (e) {
        toaster("error", "Invalid Username or password!");
        onError(e);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const forgotPassFormik = useFormik({
    initialValues: {
      email: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Email is not valid")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const payload = { ...values };
        const data = await API.post(`auth/forgot-pass`, payload);

        if (data) {
          toaster("info", "Reset link sent to the given email address");
        }
      } catch (e) {
        toaster("error", "Invalid Username!");
        onError(e);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <main className="main-content">
        <div className="container">
          <div className="pt-60 pt-30-sm pb-60 pb-30-sm">
            <div className="card-content-wrapper">
              <div className="card-item">
                <div className="btn-holder text-right">
                  <span>
                    Don’t have an account?{" "}
                    <Link
                      to={
                        params?.redirect
                          ? `/auth/signup?redirect=${params.redirect}`
                          : "/auth/signup"
                      }
                    >
                      Register
                    </Link>
                  </span>
                </div>

                <div className="card-block-wrapper max-w-370 mb-20">
                  {forgotPass ? (
                    <>
                      <form noValidate onSubmit={forgotPassFormik.handleSubmit}>
                        <div className="card-block-title pt-40 mb-30 ml-0 mr-0">
                          <h1>Forgot Password</h1>
                        </div>
                        <div className="form-wrapper">
                          <div className="form-group mb-20">
                            <label className="title-info">Your email</label>
                            <div className="input-item">
                              <Input
                                id="email"
                                label="Johnsmith@gmail.com"
                                type="email"
                                name="email"
                                formik={forgotPassFormik}
                                {...forgotPassFormik.getFieldProps("email")}
                              />
                              <img src={emailIcon} alt="email Icon" />
                            </div>
                          </div>
                        </div>

                        <div className="btn-section align-items-center">
                          <GoBack />

                          <LoaderButton
                            className="btn btn-primary"
                            type="submit"
                            buttonText="Send Email"
                            loadingText="loading"
                            isLoading={isLoading}
                          />
                        </div>
                      </form>
                      <a href={null} onClick={() => setForgotPass(false)}>
                        <span>Click here to login</span>
                      </a>
                    </>
                  ) : (
                    <>
                      <form noValidate onSubmit={formik.handleSubmit}>
                        <div className="card-block-title pt-40 mb-30 ml-0 mr-0">
                          <h1>Log in to ShareBear</h1>
                        </div>
                        <div className="social-btn">
                          <button
                            className="btn btn-white btn-block"
                            type="button"
                            onClick={() =>{
                              console.log('.....', `${config.API_BASE_URL}/auth/google`);
                              window.open(
                                `${config.API_BASE_URL}/auth/google`,
                                "_self"
                              )
                            }
                            }
                          >
                            <i className="fab fa-google"></i>
                            <span className="ml-10">Connect with Google</span>
                          </button>
                        </div>

                        <div className="divider-text">
                          <span>or</span>
                        </div>
                        <div className="form-wrapper">
                          <div className="form-group mb-20">
                            <label className="title-info">Your email</label>
                            <div className="input-item">
                              <Input
                                id="email"
                                label="Johnsmith@gmail.com"
                                type="email"
                                name="email"
                                formik={formik}
                                {...formik.getFieldProps("email")}
                              />
                              <img src={emailIcon} alt="email Icon" />
                            </div>
                          </div>

                          <div className="form-group mb-20">
                            <label className="title-info">Password</label>
                            <div className="input-item">
                              <Input
                                id="password"
                                label="Enter your password here"
                                type={type ? "text" : "password"}
                                name="password"
                                formik={formik}
                                {...formik.getFieldProps("password")}
                              />
                              <img
                                src={passwordIcon}
                                className="clickable"
                                onClick={() => setType(!type)}
                                alt="password Icon"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="apply-conditions mb-20">
                          <label className="custom-checkbox">
                            <input type="checkbox" className="custom-input" />
                            <span>Remember me</span>
                          </label>
                        </div>

                        <div className="btn-section align-items-center">
                          <GoBack />

                          <LoaderButton
                            className="btn btn-primary"
                            type="submit"
                            buttonText="Log In"
                            loadingText="loading"
                            isLoading={isLoading}
                          />
                        </div>
                      </form>
                      <div style={{ marginTop: "15px" }}>
                        <a onClick={() => setForgotPass(true)}>
                          <span>Forgot password</span>
                        </a>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default withRouter(LoginMainComponent);

import React from "react";
import ShowMoreText from "react-show-more-text";

const MarkupParagraph = ({ text, lines = 5, showMore }) => {
  function createMarkup(html) {
    return {
      __html: html,
    };
  }

  return !showMore ? (
    <p dangerouslySetInnerHTML={createMarkup(text)}></p>
  ) : (
    <p>
      <ShowMoreText
        /* Default options */
        lines={lines}
        more="Show more"
        less="Show less"
        expanded={false}
      >
        <p dangerouslySetInnerHTML={createMarkup(text)}></p>
      </ShowMoreText>
    </p>
  );
};

export default MarkupParagraph;

import React from "react";

const ReceiverVideoMessageMainContent = () => {
  return (
    <>
      <main className="main-content">
        <div className="container">
          <div className="card-content-wrapper">
            <div className="card-item mt-20 pb-20">
              <div className="reciever-content-wrapper">
                <div className="card-holder">
                  <h2 className="mb-10">Sam’s message</h2>
                  <div className="bg-color-grey text-box">
                    <div className="video-wrapper">
                      <div className="video-banner">
                        <a href="#" className="action-btn">
                          <i className="fa fa-play"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="btn-section justify-content-end">
                    <button className="btn btn-rounded btn-primary">
                      View Gift card
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ReceiverVideoMessageMainContent;

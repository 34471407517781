import React from "react";
import clsx from "clsx";

const RelevantSearchCard = ({ handleRelevantClick, title, selected }) => {
  return (
    <div className="search-card-item">
      <button
        onClick={handleRelevantClick}
        className={clsx("btn btn-transparent", selected ? "active" : "")}
      >
        {title}
      </button>
    </div>
  );
};

export default RelevantSearchCard;

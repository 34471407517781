import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useAppContext } from "../../store/appContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/Input";
import API from "../../libs/axios";
import { onError } from "../../libs/errorLib";
import publicIp from "public-ip";
import LoaderButton from "../LoaderButton/LoaderButton";
import GoBack from "../GoBack/GoBack";
import AmericaStates from '../../utils/enums/AmericaStates'
import { toaster } from "../../libs/utils";
import SweetAlert2 from 'react-sweetalert2';

import Loader from "../Loader/Loader";
import "./FundsMainLayout.css"
const PaymentMethodMainContent = () => {
    const [swalProps, setSwalProps] = useState({});
    const history = useHistory();
    const {
        setIsLoading,
        user,
        isAuthenticated,
        isLoading,
        me,
    } = useAppContext();
    const [ip, setIp] = useState(null);
    const [giftSent, setGiftSent] = useState(false);
    const [years, setYears] = useState([]);

    useEffect(() => {
        if (!user) {
            history.push("/auth/login");
        }
        window.scrollTo({ behavior: "smooth", top: 0 });
        let year = new Date().getFullYear();
        let sampleYears = [...Array(20)].map((_, i) => {
            return year + i;
        });
        setYears(sampleYears);

    }, []);

    useEffect(async () => {
        const ipAddress = await publicIp.v4({
            fallbackUrls: ["https://ifconfig.co/ip"],
        });

        if (ipAddress) {
            setIp(ipAddress);
        }
    }, []);



    const formik = useFormik({
        initialValues: {
            cardHolderName: user?.fullname ?? "",
            address: "",
            amount: "",
            city: "",
            state: "",
            country: "",
            postalCode: "",
            billingAddress: {
                addressLine1: "",
                city: "",
                country: "",
                emailAddress: user ? user.email : "",
                cardHolderName: user?.fullname ?? "",
                firstName: user?.firstname ?? "",
                lastName: user?.lastname ?? "",
                postalCode: "",
                state: "",
            },
            expiryMonth: "",
            expiryYear: "",
            number: "",
            verificationNumber: "",
            geoPoint: {
                lat: 24.774265,
                lon: 46.738586,
            },
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            number: Yup.string().required("Card Number is required"),
            amount: Yup.number().required("Amount is required"),
            expiryMonth: Yup.string()
                .min(2)
                .max(2)
                .required("Expiry Month is required"),
            expiryYear: Yup.string()
                .min(4)
                .max(4)
                .required("Expiry Year is required"),
            verificationNumber: Yup.string()
                .required("CVV is required")
                .min(3)
                .max(4),
            cardHolderName: Yup.string()
                .required("Full Name is required")
                .matches(/^[A-Za-z ]*$/, "Please enter valid name")
                .max(40),
            // firstName: Yup.string()
            //     .required("First Name is required")
            //     .matches(/^[A-Za-z ]*$/, "Please enter valid name")
            //     .max(40),
            // lastName: Yup.string()
            //     .required("Last Name is required")
            //     .matches(/^[A-Za-z ]*$/, "Please enter valid name")
            //     .max(40),
            city: Yup.string()
                .required("City is required")
                .matches(/^[A-Za-z ]*$/, "Please enter valid city"),
            country: Yup.string()
                .required("Country is required")
                .matches(/^[A-Za-z ]*$/, "Please enter valid country"),
            state: Yup.string()
                .required("State is required")
                .matches(/^[A-Za-z ]*$/, "Please enter valid state"),
            address: Yup.string().required("Address is required"),
            postalCode: Yup.string()
                .required("Postal Code is required")
                .matches(/^[0-9]+$/, "Postal Code be only digits")
                .min(5, "Must be exactly 5 digits")
                .max(5, "Must be exactly 5 digits"),
        }),
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            const fullName = values.cardHolderName;
            const firstName = fullName.split(' ').slice(0, -1).join(' ');
            const lastName = fullName.split(' ').slice(-1).join(' ');
            try {
                const payload = {
                    billingAddress: {
                        ...values.billingAddress,
                        city: values.city,
                        state: values.state,
                        country: values.country,
                        addressLine1: values.address,
                        // cardHolderName: values.cardHolderName,
                        firstName,
                        lastName,
                        postalCode: values.postalCode,

                    },

                    contactInformation: [
                        {
                            emailAddress: user ? user.email : "",
                            cardHolderName: `${values.cardHolderName}`,
                            fullName,
                        },
                    ],
                    creditCard: {
                        expiration: values.expiryYear + "-" + values.expiryMonth,
                        number: values.number,
                        amount: values.amount,
                        verificationNumber: values.verificationNumber,
                    },
                    user,
                    accountIdentifier: user.id,
                    ipAddress: ip,
                    label: "Primary",
                    status: me == "mee" ? "FINALIZED" : "SENT",
                };
                await API.post(`orders/funds`, payload);
                resetForm();
                setSwalProps({
                    show: true,
                    title: 'Funds updated successfully!',
                    text: `We are processing your request.
                    You will be notified once funds will be available
                    `,
                    icon: 'success',
                    confirmButtonColor:'#18A0FB'
                }); 
                toaster("success", "Funds updated successfully!");
                history.push("/balance");
            } catch (e) {
                const errorMessage = 'Transaction cannot be processed at this time. Please try again later. For any concerns or assistance, please contact your support representative.'
                // toaster("error", e);
                setSwalProps({
                    show: true,
                    title: 'Error',
                    text: errorMessage,
                    icon: 'error',
                    confirmButtonColor:'#18A0FB'
                }); 
                onError(e);

            } finally {
                setIsLoading(false);
            }
        },
    });

    return (
        <main className="main-content">
            <section className="gift-card-section">
                <div className="container">
                    <div className="card-content-wrapper max-w-740 payment-section">
                        <div className="card-item">
                            <div className="delivery-checkout-form">
                                <form noValidate onSubmit={formik.handleSubmit}>
                                    <div className="gc-detail-title pb-20 mb-30 b-border">
                                        <h3 className="mb-5">Add Funds</h3>
                                        <p>Please choose a payment method</p>
                                    </div>
                                    <h3 id="CardSet">Credit Card</h3>
                                    <div className="row mb-15">
                                        <div className="form-item mb-10 col-sm-12">
                                            <div className="form-group">
                                                <label className="title-info">Card Holder Name</label>
                                                <div className="input-item">
                                                    <Input
                                                        type="text"
                                                        name="cardHolderName"
                                                        id="cardHolderName"
                                                        placeholder="Full Name"
                                                        formik={formik}
                                                        {...formik.getFieldProps("cardHolderName")}
                                                    />
                                                </div>

                                            </div>
                                        </div>


                                        {/* <div className="form-item mb-10 col-sm-12">
                                            <div className="form-group">
                                                <label className="title-info">First Name</label>
                                                <div className="input-item">
                                                    <Input
                                                        type="text"
                                                        name="firstName"
                                                        id="firstName"
                                                        placeholder="First Name"
                                                        formik={formik}
                                                        {...formik.getFieldProps("firstName")}
                                                    />
                                                </div>

                                            </div>
                                        </div> */}
                                        {/* <div className="form-item mb-10 col-sm-12">
                                            <div className="form-group">
                                                <label className="title-info">Last Name</label>
                                                <div className="input-item">
                                                    <Input
                                                        type="text"
                                                        name="lastName"
                                                        id="lastName"
                                                        placeholder="Last Name"
                                                        formik={formik}
                                                        {...formik.getFieldProps("lastName")}
                                                    />
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="form-item mb-10 col-sm-12">
                                            <div className="form-group">
                                                <label className="title-info">Card Number</label>
                                                <div className="input-item">
                                                    <Input
                                                        name="cc-number"
                                                        id="number"
                                                        inputMode="numeric"
                                                        pattern="[0-9\s]{13,19}"
                                                        autoComplete="cc-number"
                                                        maxLength="19"
                                                        placeholder="xxxx xxxx xxxx xxxx"
                                                        formik={formik}
                                                        {...formik.getFieldProps("number")}
                                                    />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M13.5 2.5H2.5C1.9 2.5 1.5 2.9 1.5 3.5V11.5C1.5 12.1 1.9 12.5 2.5 12.5H13.5C14.1 12.5 14.5 12.1 14.5 11.5V3.5C14.5 2.9 14.1 2.5 13.5 2.5Z"
                                                            className="aaa"
                                                        />
                                                        <path
                                                            d="M5 11C4.7 11 4.5 11.1 4.3 11.3 4.1 11.5 4 11.7 4 12 4 12.3 4.1 12.5 4.3 12.7 4.5 12.9 4.7 13 5 13 5.3 13 5.5 12.9 5.7 12.7 5.9 12.5 6 12.3 6 12 6 11.7 5.9 11.5 5.7 11.3 5.5 11.1 5.3 11 5 11ZM11 11C10.7 11 10.5 11.1 10.3 11.3 10.1 11.5 10 11.7 10 12 10 12.3 10.1 12.5 10.3 12.7 10.5 12.9 10.7 13 11 13 11.3 13 11.5 12.9 11.7 12.7 11.9 12.5 12 12.3 12 12 12 11.7 11.9 11.5 11.7 11.3 11.5 11.1 11.3 11 11 11Z"
                                                            fill="#52B8FC"
                                                        />
                                                        <path
                                                            d="M10 8.5H13M6 4.5C5.6 4.5 5.2 4.7 4.9 4.9 4.7 5.2 4.5 5.6 4.5 6 4.5 6.4 4.7 6.8 4.9 7.1 5.2 7.3 5.6 7.5 6 7.5 6.4 7.5 6.8 7.3 7.1 7.1 7.3 6.8 7.5 6.4 7.5 6 7.5 5.6 7.3 5.2 7.1 4.9 6.8 4.7 6.4 4.5 6 4.5ZM3.5 10C3.5 8.6 4.6 7.5 6 7.5 7.4 7.5 8.5 8.6 8.5 10H3.5ZM10 6.5H13 10Z"
                                                            className="aaa"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-item mb-10 col-sm-6">
                                            <div className="form-group">
                                                <label className="title-info">Expiry Year</label>
                                                <div className="select-item">
                                                    <select
                                                        formik={formik}
                                                        name="cc-exp-year"
                                                        autoComplete="cc-exp-year"
                                                        className={`form-control${formik.errors["expiryYear"] &&
                                                            formik.touched["expiryYear"]
                                                            ? " is-invalid"
                                                            : ""
                                                            }`}
                                                        style={{ paddingBottom: "12px" }}
                                                        {...formik.getFieldProps("expiryYear")}
                                                    >
                                                        <option value="" disabled>
                                                            Select Year
                                                        </option>
                                                        {years &&
                                                            years.map((year) => (
                                                                <option key={year} value={year}>
                                                                    {year}
                                                                </option>
                                                            ))}
                                                    </select>
                                                    {formik.errors["expiryYear"] && (
                                                        <div className="invalid-feedback">
                                                            {formik.errors["expiryYear"]}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-item mb-10 col-sm-6">
                                            <div className="form-group">
                                                <label className="title-info">Expiry Month</label>
                                                <div className="select-item">
                                                    <select
                                                        style={{ paddingBottom: "12px" }}
                                                        className={`form-control${formik.errors["expiryMonth"] &&
                                                            formik.touched["expiryMonth"]
                                                            ? " is-invalid"
                                                            : ""
                                                            }`}
                                                        defaultValue=""
                                                        name="cc-exp-month"
                                                        autoComplete="cc-exp-month"
                                                        formik={formik}
                                                        {...formik.getFieldProps("expiryMonth")}
                                                    >
                                                        <option value="" disabled>
                                                            Select Month
                                                        </option>
                                                        <option selected value="01">
                                                            Janaury
                                                        </option>
                                                        <option value="02">February</option>
                                                        <option value="03">March</option>
                                                        <option value="04">April</option>
                                                        <option value="05">May</option>
                                                        <option value="06">June</option>
                                                        <option value="07">July</option>
                                                        <option value="08">August</option>
                                                        <option value="09">September</option>
                                                        <option value="10">October</option>
                                                        <option value="11">November</option>
                                                        <option value="12">December</option>
                                                    </select>
                                                    {formik.errors["expiryMonth"] && (
                                                        <div className="invalid-feedback">
                                                            {formik.errors["expiryMonth"]}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-item mb-10 col-sm-6">
                                            <div className="form-group">
                                                <label className="title-info">Security code</label>
                                                <div className="input-item">
                                                    <Input
                                                        type="text"
                                                        name="cc-csc"
                                                        id="verificationNumber"
                                                        placeholder="CVV"
                                                        autoComplete="cc-csc"
                                                        maxLength="4"
                                                        formik={formik}
                                                        {...formik.getFieldProps("verificationNumber")}
                                                    />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="17"
                                                        height="17"
                                                        viewBox="0 0 17 17"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M4 13.3H2.4C1.6 13.3 1 12.7 1 11.9V4.4C1 3.7 1.6 3.1 2.4 3.1H14.6C15.4 3.1 16 3.7 16 4.4V11.9C16 12.7 15.4 13.3 14.6 13.3H13"
                                                            stroke="#52B8FC"
                                                        />
                                                        <path
                                                            d="M1 5.1V6.8H5.5C6.3 6.2 7.4 5.8 8.5 5.8 9.6 5.8 10.7 6.2 11.5 6.8H16V5.1H1Z"
                                                            className="bbb"
                                                        />
                                                        <path
                                                            d="M8.5 5.8C7.1 5.8 5.9 6.3 4.9 7.3 3.9 8.2 3.4 9.5 3.4 10.9 3.4 12.2 3.9 13.5 4.9 14.5 5.9 15.4 7.1 16 8.5 16 9.9 16 11.2 15.4 12.1 14.5 13.1 13.5 13.6 12.2 13.6 10.9 13.6 9.5 13.1 8.2 12.1 7.3 11.2 6.3 9.9 5.8 8.5 5.8Z"
                                                            className="ccc"
                                                        />
                                                        <path
                                                            d="M5.3 11C5.3 10.3 5.7 9.9 6.4 9.9 6.9 9.9 7.3 10.2 7.3 10.7H6.9C6.8 10.4 6.6 10.3 6.4 10.3 6 10.3 5.8 10.5 5.8 11 5.8 11.4 6 11.7 6.4 11.7 6.6 11.7 6.8 11.6 6.9 11.3H7.3C7.3 11.8 6.9 12.1 6.4 12.1 5.7 12.1 5.3 11.7 5.3 11ZM8.4 12.1L7.7 9.9H8.2L8.7 11.6H8.7L9.2 9.9H9.7L9 12.1H8.4ZM10.8 12.1L10.1 9.9H10.6L11.1 11.6H11.1L11.6 9.9H12.1L11.3 12.1H10.8Z"
                                                            className="bbb"
                                                        />
                                                    </svg>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="form-item mb-10 col-sm-12">
                                            <hr />
                                            <div className="form-group">
                                                <h3 id="CardSet2">Billing Address</h3>
                                                <label className="title-info">Address</label>
                                                <div className="input-item">
                                                    <Input
                                                        type="text"
                                                        name="address"
                                                        id="address"
                                                        placeholder="Address"
                                                        formik={formik}
                                                        {...formik.getFieldProps("address")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-item mb-10 col-sm-6">
                                            <div className="form-group">
                                                <label className="title-info">City</label>
                                                <div className="input-item">
                                                    <Input
                                                        type="text"
                                                        name="city"
                                                        id="city"
                                                        placeholder="City"
                                                        formik={formik}
                                                        {...formik.getFieldProps("city")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-item mb-10 col-sm-6">
                                            <div className="form-group">
                                                <label className="title-info">State</label>
                                                <div className="input-item">
                                                    <select
                                                        formik={formik}
                                                        id="state"
                                                        name="state"
                                                        autoComplete="state"
                                                        className={`form-control${formik.errors["state"] &&
                                                            formik.touched["state"]
                                                            ? " is-invalid"
                                                            : ""
                                                            }`}
                                                        style={{ paddingBottom: "12px" }}
                                                        {...formik.getFieldProps("state")}
                                                    >
                                                        <option value="" disabled>
                                                            Select State
                                                        </option>
                                                        {AmericaStates &&
                                                            AmericaStates.map((state) => (
                                                                <option key={state} value={state}>
                                                                    {state}
                                                                </option>
                                                            ))}
                                                    </select>
                                                    {formik.errors["state"] && (
                                                        <div className="invalid-feedback">
                                                            {formik.errors["state"]}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-item mb-10 col-sm-6">
                                            <div className="form-group">
                                                <label className="title-info">Country</label>
                                                <div className="input-item">
                                                    <select
                                                        formik={formik}
                                                        id="country"
                                                        name="country"
                                                        autoComplete="country"
                                                        className={`form-control${formik.errors["country"] &&
                                                            formik.touched["country"]
                                                            ? " is-invalid"
                                                            : ""
                                                            }`}
                                                        style={{ paddingBottom: "12px" }}
                                                        {...formik.getFieldProps("country")}
                                                    >
                                                        <option disabled value="">
                                                            Select Country
                                                        </option>
                                                        <option key="america" value="usa">
                                                            USA
                                                        </option>
                                                    </select>
                                                    {formik.errors["country"] && (
                                                        <div className="invalid-feedback">
                                                            {formik.errors["country"]}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-item mb-10 col-sm-6">
                                            <div className="form-group">
                                                <label className="title-info">Postal Code</label>
                                                <div className="input-item">
                                                    <Input
                                                        type="text"
                                                        name="postalCode"
                                                        id="postalCode"
                                                        placeholder="Postal Code"
                                                        formik={formik}
                                                        {...formik.getFieldProps("postalCode")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-item mb-10 col-sm-12">
                                            <div className="form-group">
                                                <hr />
                                                <h3 id="CardSet2">Amounts</h3>
                                                <label className="title-info">Amount</label>
                                                <div className="input-item">
                                                    <Input
                                                        type="text"
                                                        name="amount"
                                                        id="amount"
                                                        placeholder="Amount"
                                                        formik={formik}
                                                        {...formik.getFieldProps("amount")}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="apply-conditions mb-30">
                                        <h5>
                                            <span>Share Bear </span>{" "}
                                            <a href="/terms-of-service">Terms and Conditions</a>
                                        </h5>
                                        <p>
                                            The payment flow is powered by <a href="https://www.tangocard.com/">Tango Card</a>.
                                            By clicking Place order, you have read and you agree to
                                            the above <a href="">terms and conditions.</a>
                                        </p>
                                    </div>

                                    <div className="btn-section align-items-center">
                                        <GoBack />

                                        <LoaderButton
                                            className="btn btn-primary"
                                            type="submit"
                                            buttonText="Place Order"
                                            loadingText="loading"
                                            isLoading={isLoading}
                                        />
                                    </div>
                                </form>
                                {giftSent && <Redirect to={`/gift-sent/${orderId}`} />}
                            </div>
                        </div>
                    </div>
                </div>
                <SweetAlert2 {...swalProps} />

            </section>
        </main>
    );
};

export default PaymentMethodMainContent;

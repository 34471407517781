import React from "react";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../store/appContext";

const EnlightenSection = ({ image }) => {
  const history = useHistory();
  const {
    isAuthenticated,
  } = useAppContext();

  return (
    <div className="container">
        <div className="content-holder">
            <div className="image-holder">
                <img src={image} alt="ShareBear Image" className="img-fluid"/>
            </div>
            <div className="content-description">
                <h2>Raise the vibration of the planet</h2>
                <p>ShareBear is a single spot where anyone can show their appreciation to a customer, teacher, family or friend by gifting and re-gifting random acts of gratitude.</p>
                <div className="action-btns">
                    <a onClick={() => history.push("/choose-gift-card")} className="link-item"><span>Send gift card </span><i className="fa fa-angle-right"></i></a>
                    {!isAuthenticated ? <a onClick={() => history.push("/auth/login")} className="link-item"><span>Sign In </span><i className="fa fa-angle-right"></i></a> : ''}
                </div>
            </div>
        </div>
    </div>
  );
};

export default EnlightenSection;

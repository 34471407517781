import React, { useEffect, useState } from "react";
import { Link, Redirect, useParams, withRouter } from "react-router-dom";
import passwordIcon from "../../images/icon/password-icon.svg";
import { useAppContext } from "../../store/appContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/Input";
import API from "../../libs/axios";
import { onError } from "../../libs/errorLib";
import LoaderButton from "../LoaderButton/LoaderButton";
import GoBack from "../GoBack/GoBack";

const ResetPasswordComponent = ({ history }) => {
  const { code } = useParams();
  const { isLoading, setIsLoading } = useAppContext();
  const [verified, setVerified] = useState();
  const [passChanged, setPassChanged] = useState(false);
  const [type, setType] = useState(false);
  const [cType, setCType] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    if (code) {
      verifyResetCode();
    }
  }, []);

  const verifyResetCode = async () => {
    try {
      const verify = await API.post(`auth/verify/${code}`);
      if (verify) {
        setVerified(verify);
      }
    } catch (e) {
      onError({ message: "Link is already used or expired" });
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string()
        .label("Confirm Password")
        .required()
        .oneOf(
          [Yup.ref("password"), null],
          "Password and Confirm password should match"
        ),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        values.reset_code = code;
        values.user_id = verified.user_id;

        await API.post(`auth/reset-pass/`, values);
      } catch (e) {
        onError("Link is already used or expired");
      } finally {
        setPassChanged(true);
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <main className="main-content">
        <div className="container">
          <div className="pt-60 pt-30-sm pb-60 pb-30-sm">
            <div className="card-content-wrapper">
              <div className="card-item">
                <div className="btn-holder text-right">
                  <span>
                    Don’t have an account?{" "}
                    <Link to="/auth/signup">Register</Link>
                  </span>
                </div>

                <div className="card-block-wrapper max-w-370 mb-20">
                  <form noValidate onSubmit={formik.handleSubmit}>
                    <div className="card-block-title pt-40 mb-30 ml-0 mr-0">
                      <h1>Set Password</h1>
                    </div>
                    <div className="form-wrapper">
                      <div className="form-group mb-20">
                        <label className="title-info">Password</label>
                        <div className="input-item">
                          <Input
                            id="password"
                            label="Password"
                            type={type ? "text" : "password"}
                            name="password"
                            formik={formik}
                            {...formik.getFieldProps("password")}
                          />
                          <img
                            src={passwordIcon}
                            className="clickable"
                            onClick={() => setType(!type)}
                            alt="password Icon"
                          />
                        </div>
                      </div>

                      <div className="form-group mb-20">
                        <label className="title-info">Confirm Password</label>
                        <div className="input-item">
                          <Input
                            id="confirmPassword"
                            label="Confirm Password"
                            type={cType ? "text" : "password"}
                            name="confirmPassword"
                            formik={formik}
                            {...formik.getFieldProps("confirmPassword")}
                          />
                          <img
                            src={passwordIcon}
                            className="clickable"
                            onClick={() => setCType(!cType)}
                            alt="password Icon"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="btn-section align-items-center">
                      <GoBack />

                      <LoaderButton
                        className="btn btn-primary"
                        type="submit"
                        buttonText="Reset Password"
                        loadingText="loading"
                        isLoading={isLoading}
                      />
                      {passChanged && <Redirect to="/auth/login" />}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default withRouter(ResetPasswordComponent);

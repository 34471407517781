import React from "react";

const GiftContentItem = ({ title, image, text }) => {
  return (
    <div className="gift-content-item">
      <div className="content-holder">
        <div className="icon-holder">
          <img src={image} alt="Send Gift" className="img-fluid" />
        </div>
        <div className="title">{title}</div>
        <div className="description">{text}</div>
      </div>
    </div>
  );
};

export default GiftContentItem;

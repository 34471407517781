import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/Input";
import API from "../../libs/axios";
import { useAppContext } from "../../store/appContext";
import { onError } from "../../libs/errorLib";
import clsx from "clsx";
import LoaderButton from "../LoaderButton/LoaderButton";
import HavingProblems from "../HavingProblems/HavingProblems";

const SendGiftCardMainContent = () => {
  const history = useHistory();
  const { orderId } = useParams();
  const { isLoading, setIsLoading, user, me, setMe } = useAppContext();
  const [order, setOrder] = useState(null);
  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
    if (orderId) {
      getOrder();
    }
  }, []);
  const getOrder = async () => {
    const order = await API.get(`orders/${orderId}`);
    if (order) {
      setOrder(order);
    }
  };
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      your_name: user && user?.firstname ? user?.firstname+" "+user?.lastname : '',
      recipient_name: "",
    },
    validationSchema: Yup.object().shape({
      your_name: Yup.string().required("Your Name is required"),
      recipient_name: Yup.string().required("Recipient's Name is required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        if (orderId) {
          const response = await API.put(`orders/${orderId}`, {
            recipient: {
              firstName: values.recipient_name,
            },
            sender: {
              firstName: values.your_name,
              email: user?.email
            },
          });
          history.push(`/delivery-message/${response._id}`);
        } else {
          const response = await API.post(`orders`, {
            recipient: {
              firstName: values.recipient_name,
            },
            sender: {
              firstName: values.your_name,
            },
            order,
          });
          history.push(`/delivery-message/${response._id}`);
        }
      } catch (e) {
        onError(e);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const formik1 = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: order?.sender?.your_name,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Your Name is required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        if (orderId) {
          const order = await API.put(`orders/${orderId}`, {
            recipient: {
              firstName: values.name,
            },
            sender: {
              firstName: values.name,
            },
          });
          //   history.push(`/delivery-message/${order._id}`);
          history.push(`/checkout/${orderId}`);
        } else {
          const order = await API.post(`orders`, {
            recipient: {
              firstName: values.name,
            },
            sender: {
              firstName: values.name,
            },
          });
          //   history.push(`/delivery-message/${order._id}`);
          history.push(`/checkout/${order._id}`);
        }
      } catch (e) {
        onError(e);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <main className="main-content">
        <section className="gift-card-section">
          <div className="container">
            <div className="card-content-wrapper">
              <div className="card-item">
                <h2 className="mt-40 mb-40 text-center">
                  Send a ShareBear Gift Card
                </h2>
                <div className="tab-list-section">
                  {/* <!-- Nav tabs --> */}
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        className={clsx(
                          "nav-link",
                          me == "friend" ? "active" : ""
                        )}
                        // data-toggle="tab"
                        // href="#forFriendTab"
                        onClick={() => setMe("friend")}
                      >
                        It’s for a friend
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={clsx(
                          "nav-link",
                          me == "mee" ? "active" : ""
                        )}
                        // data-toggle="tab"
                        // href="#forMeTab"
                        onClick={() => setMe("mee")}
                      >
                        It’s for me
                      </a>
                    </li>
                  </ul>

                  {/* <!-- Tab panes --> */}
                  <div className="pt-10">
                    {me == "mee" && (
                      <div className="content-holder">
                        <p className="mb-40">
                          Show your appreciation and spread some joy!{" "}
                        </p>
                        <form onSubmit={formik1.handleSubmit}>
                          <div className="form-group">
                            <label htmlFor="name">Your name</label>
                            <Input
                              type="text"
                              name="your_name"
                              id="your_name"
                              placeholder="Enter your name here"
                              formik={formik1}
                              {...formik1.getFieldProps("your_name")}
                            />
                          </div>
                          <div className="btn-section mb-60 pt-40">
                            <Link
                              to={`/detailed-gift-card/${
                                order !== null && order.brandId.brandName
                              }/${order !== null && order.brandId.brandKey}/${
                                order !== null && order.brandId.id
                              }/${orderId}`}
                              className="back-btn"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="8"
                                height="13"
                                viewBox="0 0 8 13"
                                fill="none"
                              >
                                <path
                                  d="M3.3 6.5L7.3 2.5C7.8 2.1 7.8 1.4 7.3 1 6.9 0.5 6.2 0.5 5.8 1L1 5.8C0.5 6.2 0.5 6.8 1 7.2L5.8 12C6.2 12.5 6.9 12.5 7.3 12 7.8 11.6 7.8 10.9 7.3 10.5L3.3 6.5Z"
                                  fill="currentColor"
                                />
                              </svg>
                              <span className="ml-4">Go Back</span>
                            </Link>

                            <LoaderButton
                              className="btn btn-primary"
                              type="submit"
                              buttonText="Go to Checkout"
                              loadingText="loading"
                              isLoading={isLoading}
                            />
                          </div>
                        </form>
                        <div className="text-right">
                          <HavingProblems />
                        </div>
                      </div>
                    )}
                    {me == "friend" && (
                      <div className="content-holder">
                        <p className="mb-40">
                          Show your appreciation and spread some joy!{" "}
                        </p>
                        <form noValidate onSubmit={formik.handleSubmit}>
                          <div className="form-group">
                            <label htmlFor="recipient_name">
                              Recipient’s name
                            </label>
                            <Input
                              type="text"
                              name="recipient_name"
                              id="recipient_name"
                              placeholder="Enter your friend’s name here"
                              formik={formik}
                              {...formik.getFieldProps("recipient_name")}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="your_name">Your name</label>
                            <Input
                              type="text"
                              name="your_name"
                              id="your_name"
                              placeholder="Enter your name here"
                              formik={formik}
                              {...formik.getFieldProps("your_name")}
                            />
                          </div>
                          <div className="btn-section mb-60 pt-40">
                            <Link
                              to={`/detailed-gift-card/${
                                order !== null && order.brandId.brandName
                              }/${order !== null && order.brandId.brandKey}/${
                                order !== null && order.brandId.id
                              }/${orderId}`}
                              className="back-btn"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="8"
                                height="13"
                                viewBox="0 0 8 13"
                                fill="none"
                              >
                                <path
                                  d="M3.3 6.5L7.3 2.5C7.8 2.1 7.8 1.4 7.3 1 6.9 0.5 6.2 0.5 5.8 1L1 5.8C0.5 6.2 0.5 6.8 1 7.2L5.8 12C6.2 12.5 6.9 12.5 7.3 12 7.8 11.6 7.8 10.9 7.3 10.5L3.3 6.5Z"
                                  fill="currentColor"
                                />
                              </svg>
                              <span className="ml-4">Go Back</span>
                            </Link>

                            <LoaderButton
                              className="btn btn-primary"
                              type="submit"
                              buttonText="Go to Delivery Message"
                              loadingText="loading"
                              isLoading={isLoading}
                            />
                          </div>
                        </form>
                        <div className="text-right">
                          <HavingProblems />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default SendGiftCardMainContent;

import React from "react";
import HomePageHeader from "../components/Header/HomePageHeader";

const ReceiverMainLayout = ({ children }) => {
  return (
    <>
      <HomePageHeader />
      <div className="white-color reciever-home">{children}</div>
    </>
  );
};

export default ReceiverMainLayout;

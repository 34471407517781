import React from "react";
import queryString from "query-string";
import { Redirect } from "react-router-dom";
import { useAppContext } from "../../store/appContext";

const AuthToken = ({ location }) => {
  const { token } = queryString.parse(location.search);
  const { userHasAuthenticated, redirect } = useAppContext();

  if (token) {
    localStorage.setItem("token", token);
    userHasAuthenticated(true);
    return <Redirect to={redirect !== undefined ? redirect : "/home"} />;
  }
  return <p></p>;
};

export default AuthToken;

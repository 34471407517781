import React from "react";
import '../scss/atomicStructure/atomic.scss'

const SmallTitle = (props) => {

  return (
    <p className="templateSmallTitle mb-0">
      {props.text}
    </p>
  );
};

export default SmallTitle;

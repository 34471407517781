import React from "react";
import Header from "../../components/Header/Header";
import ResetPasswordComponent from "../../components/ResetPassword/ResetPasswordContent";

function ResetPassword() {
  return (
    <>
      <Header />
      <ResetPasswordComponent />
    </>
  );
}

export default ResetPassword;

export default {
	// API_BASE_URL: "http://localhost:1338/v1-qa",
	API_BASE_URL: "https://api.sharebear.gift/v1-qa",
	GOOGLE_KEY: 'AIzaSyCWdJvCPs5xEiSPB1Hn_CGFBD636RKGcUk',
	SUPPORT_EMAIL: "support@sharebear.com",
	PAGINATOR_SIZE: 10,
	s3: {
		REGION: 'us-east-1',
		BUCKET: 'sharebear-media-assets',
		BUCKET_URL: 'https://sharebear-media-assets.s3.us-east-2.amazonaws.com/public/',
	}
};

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import bearIcon from "../../images/grey-bear.svg";
import { useAppContext } from "../../store/appContext";
import GoBack from "../GoBack/GoBack";
import HavingProblems from "../HavingProblems/HavingProblems";
import UploadedContact from "../UploadedContact/UploadedContact";

const SearchRecipientMain = () => {
  const { orderId } = useParams();
  const { contacts, setContacts } = useAppContext();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [filteredContacts, setFilteredContacts] = useState([]);

  useEffect((_) => {
    if (!contacts || contacts.length === 0) {
      history.push(`/upload-contacts/${orderId}`);
    }
  });

  useEffect(
    (_) => {
      if (!search) {
        setFilteredContacts(contacts);
      } else {
        setFilteredContacts(
          contacts.filter((contact) => {
            return (
              contact.name.indexOf(search) > -1 ||
              contact.email.indexOf(search) > -1
            );
          })
        );
      }
    },
    [search]
  );

  const sortContacts = () => {
    setFilteredContacts(
      ...filteredContacts.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    );
  };

  return (
    <main className="main-content">
      <div className="container">
        <div className="max-w-600 pt-60 pt-30-sm pb-60 pb-30-sm">
          <div className="card-content-wrapper">
            <div className="card-item">
              <div className="card-block-wrapper max-w-410 mb-20 mb-15-sm search-recipient">
                <div className="info-text text-right">
                  <HavingProblems />
                </div>
                <div className="card-block-title pt-40 mb-15">
                  <h1>Search Recipient</h1>
                </div>
                <div className="search-holder mb-40">
                  <i className="fa fa-search"></i>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search with name or email"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>
                <div className="card-block-title">
                  <h4 className="mb-15">Uploaded contacts</h4>
                  <a
                    onClick={(_) => {
                      setFilteredContacts([]);
                      sortContacts();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M11.8 11C11.7 11 11.6 11 11.6 11 11.5 11 11.4 11 11.4 11 11.3 10.9 10 9.8 9.2 8.6 9.2 8.5 9.2 8.4 9.2 8.3 9.3 8.2 9.4 8.1 9.5 8.1H10.6C10.7 7.4 10.7 3.7 10.8 3.6 10.9 3.4 11.3 3.3 11.6 3.3 11.9 3.3 12.2 3.4 12.3 3.6 12.4 3.7 12.5 7.4 12.6 8.1H13.7C13.8 8.1 13.9 8.2 13.9 8.3 14 8.4 14 8.5 13.9 8.6 13.1 9.8 11.8 10.9 11.8 11ZM5.5 5.8L3.7 0.1C3.7 0 3.7 0 3.7 0H2C1.9 0 1.9 0 1.9 0.1L0.1 5.8C0.1 5.8 0.1 5.9 0.1 5.9 0.1 5.9 0.2 5.9 0.2 5.9H1.5C1.6 5.9 1.6 5.9 1.6 5.9L2 4.4H3.5L4 5.9C4 5.9 4 5.9 4 5.9H5.4C5.4 5.9 5.4 5.9 5.4 5.9 5.5 5.9 5.5 5.9 5.5 5.8 5.5 5.8 5.5 5.8 5.5 5.8ZM3.3 3.3H2.3L2.6 2.2C2.6 2 2.7 1.8 2.7 1.5 2.7 1.5 2.8 1.4 2.8 1.4 2.8 1.7 2.9 2 3 2.2L3.3 3.3ZM5.1 12.7H2.5L5.1 9C5.1 9 5.1 8.9 5.1 8.9V8.2C5.1 8.1 5.1 8.1 5 8.1H0.8C0.8 8.1 0.8 8.1 0.8 8.2V9.2C0.8 9.3 0.8 9.3 0.8 9.3H3.2L0.5 13.2C0.5 13.2 0.5 13.2 0.5 13.2V13.9C0.5 14 0.5 14 0.6 14H5.1C5.1 14 5.1 14 5.1 13.9V12.8C5.1 12.8 5.1 12.7 5.1 12.7Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </div>
                <nav className="upload-contact-wrapper">
                  <ul className="v-list boxscroll">
                    {filteredContacts.map((contact, i) => (
                      <UploadedContact
                        key={i}
                        name={contact.name}
                        email={contact.email}
                        image={bearIcon}
                      />
                    ))}
                    {(!filteredContacts || !filteredContacts.length) && (
                      <p>No contacts found</p>
                    )}
                  </ul>
                  <div className="btn-section align-items-center">
                    <GoBack />
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SearchRecipientMain;

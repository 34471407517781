import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/Input";
import API from "../../libs/axios";
import { useAppContext } from "../../store/appContext";
import { onError } from "../../libs/errorLib";
import LoaderButton from "../LoaderButton/LoaderButton";
import HavingProblems from "../HavingProblems/HavingProblems";
import { setRegion } from "react-geocode";

const ReSendGiftCardMainContent = () => {
  const history = useHistory();
  const { orderId } = useParams();
  const { setIsLoading, isLoading, setRedirect } = useAppContext();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (orderId) {
      getOrder();
      setRedirect();
    }
  }, []);

  const getOrder = async () => {
    const order = await API.get(`orders/${orderId}`);
    if (order) {
      setOrder(order);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      recipient_email: "",
      recipient_name: "",
    },
    validationSchema: Yup.object().shape({
      recipient_email: Yup.string().required("Recipient's Email is required"),
      recipient_name: Yup.string().required("Recipient's Name is required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const response = await API.put(`orders/${orderId}`, {
          ...order,
          recipient: {
            firstName: values.recipient_name,
            email: values.recipient_email,
          },
          sender: {
            firstName: order.recipient.firstName,
            email: order?.recipient.email,
          },
        });
        history.push(`/gift-sent/${orderId}`);
      } catch (e) {
        onError(e);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <main className="main-content">
        <section className="gift-card-section">
          <div className="container">
            <div className="card-content-wrapper">
              <div className="card-item">
                <h2 className="mt-40 mb-40 text-center">
                  Re Send a ShareBear Gift Card
                </h2>
                <div className="tab-list-section">
                  {/* <!-- Nav tabs --> */}
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#forFriendTab"
                      >
                        It’s for a friend
                      </a>
                    </li>
                  </ul>

                  {/* <!-- Tab panes --> */}
                  <div className="tab-content pt-10">
                    <div className="tab-pane active" id="forFriendTab">
                      <div className="content-holder">
                        <p className="mb-40">
                          Show your appreciation and spread some joy!{" "}
                        </p>
                        <form noValidate onSubmit={formik.handleSubmit}>
                          <div className="form-group">
                            <label htmlFor="recipient_name">
                              Recipient’s name
                            </label>
                            <Input
                              type="text"
                              name="recipient_name"
                              id="recipient_name"
                              placeholder="Enter your friend’s name here"
                              formik={formik}
                              {...formik.getFieldProps("recipient_name")}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="recipient_email">
                              Recipient’s email
                            </label>
                            <Input
                              type="text"
                              name="recipient_email"
                              id="recipient_email"
                              placeholder="Enter your friend's email here"
                              formik={formik}
                              {...formik.getFieldProps("recipient_email")}
                            />
                          </div>
                          <div className="btn-section mb-60 pt-40">
                            <Link
                              to={`/receiver-regift-gc/${orderId}`}
                              className="back-btn"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="8"
                                height="13"
                                viewBox="0 0 8 13"
                                fill="none"
                              >
                                <path
                                  d="M3.3 6.5L7.3 2.5C7.8 2.1 7.8 1.4 7.3 1 6.9 0.5 6.2 0.5 5.8 1L1 5.8C0.5 6.2 0.5 6.8 1 7.2L5.8 12C6.2 12.5 6.9 12.5 7.3 12 7.8 11.6 7.8 10.9 7.3 10.5L3.3 6.5Z"
                                  fill="currentColor"
                                />
                              </svg>
                              <span className="ml-4">Go Back</span>
                            </Link>
                            {/* <button type="submit" className="btn btn-primary">
                              Send gift card
                            </button> */}
                            <LoaderButton
                              className="btn btn-primary"
                              type="submit"
                              buttonText="Send gift card"
                              loadingText="loading"
                              isLoading={isLoading}
                            />
                          </div>
                        </form>
                        <div className="text-right">
                          <HavingProblems />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ReSendGiftCardMainContent;

import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

const ReceiverLayout = ({ children }) => {
  return (
    <div className="gradient-color reciever-pages">
      <Header />
      {children}
    </div>
  );
};

export default ReceiverLayout;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import gistSentImage from "../../images/gift-sent.svg";
import API from "../../libs/axios";
import { useAppContext } from "../../store/appContext";
import queryString from "query-string";
import GoBack from "../GoBack/GoBack";

const GiftSentMainContent = () => {
  const { orderId } = useParams();

  let { receiver } = queryString.parse(location.search);

  const [order, setOrder] = useState(null);
  const { setOrderId } = useAppContext();

  useEffect(async () => {
    setOrderId();
    if (orderId) {
      getOrder();
    }
  }, []);

  const getOrder = async () => {
    const order = await API.get(`orders/${orderId}`);
    if (order) {
      setOrder(order);
    }
  };

  return (
    <main className="main-content">
      <div className="container card-flex">
        <div className="gift-sent-card popup-box pt-40 pb-40">
          <div className="card-white-holder">
            <div className="card-item">
              <div className="card-block-wrapper max-w-370 mb-0">
                <div className="icon-holder">
                  <img src={gistSentImage} alt="Icon" className="img-fluid" />
                </div>
                <div className="card-block-title mb-30">
                  {receiver ? (
                    <h1 className="mb-5">Gift card has been received! </h1>
                  ) : (
                    <h1 className="mb-5">Gift card has been sent! </h1>
                  )}

                  <p>
                    Gift of <strong>${order?.amount}</strong>{" "}
                    {order?.brandId?.brandName} has been {" "}
                    {receiver ? "received from" : "sent to"}{" "}
                    <a href="#">
                      {receiver
                        ? order?.sender?.firstName
                        : order?.recipient?.email}
                    </a>
                    . Thank you for becoming a ShareBear!
                  </p>
                </div>

                <div className="btn-section align-items-center">
                  <div className="btn-link mb-0">
                    <GoBack label="Home" url="/home"/>
                  </div>

                  <Link to="/choose-gift-card">
                    <button className="btn btn-rounded btn-primary">
                    {
                      receiver ? 
                      'Send gift' : 'Send another gift'
                    }  
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default GiftSentMainContent;

import React from "react";
import { useHistory } from "react-router-dom";
import "../../scss/atomicStructure/atomic.scss";
import AppleIcon from "../../images/icon/apple-icon.svg";
import ThirdTitle from "../../AtomicStructure/ThirdTitle";
import Text from "../../AtomicStructure/SecondaryTitle";
import SecondaryTitle from "../../AtomicStructure/SecondaryTitle";
import SmallTitle from "../../AtomicStructure/SmallTitle";
import SmallText from "../../AtomicStructure/SmallText";

const SentRecievedGiftList = (props) => {
  const history = useHistory();

  return (
    <div className={`sentRecievedGiftList ${props.link ? 'cursor-pointer' :'' }`} onClick={()=>{props.link && history.push(props.link)}}>
      <div className="row">
        <div className="col-2 margin-auto">
          <img src={props?.image} className="sentRecievedIcons" alt="Apple Icon" />
        </div>
        <div className="col-10 margin-auto">
          <div className="row">
            <div className="col-12">
              <ThirdTitle text={props?.message} />
            </div>
            <div className="col-2 pr-0">
              <SmallTitle text={`$ ${props?.amount}`} />
            </div>
            {props?.receiver ? (
              <div className="col-6  pl-0">
                <SmallText text={`Sent to ${props?.receiver}`} />
              </div>
            ) : (
              <div className="col-6  pl-0">
                <SmallText text={`Sent by ${props?.sender}`} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SentRecievedGiftList;

import React, { useEffect } from "react";
import StaticBanner from "../../components/StaticBanner/StaticBanner";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);
  return (
    <main className="main-content">
      <StaticBanner
        heading="Privacy & Policy"
        subHeading="Last modified: June 15, 2023"
      />
      <section className="content-block-section">
        <div className="card-wrapper">
          <div className="text-block">
            <h3>Privacy Policy</h3>
            <p>
              Effective Date Thursday 15th , june , 2023 This Privacy Policy
              describes how Share Gifts and Payments App ("the App," "we," "us,"
              or "our") collects, uses, and discloses information when you use
              our mobile application on iOS and Android devices, as well as our
              web application (collectively referred to as "the App").
            </p>
          </div>

          <div className="text-block">
            <h3>Information We Collect</h3>
            <p>
              <br />
              • Personal Information: When you create an account with the
              App, we may collect personal information such as your name, email
              address, phone number, and billing information. This information
              is necessary for the proper functioning of the App and to provide
              you with our services.
              <br />
              • Usage Information: We may collect information about your
              interaction with the App, including but not limited to your device
              type, IP address, browser type, operating system, and the pages or
              features of the App that you access.
              <br />• 1.3 Transaction Information: When you make payments or
              send/receive gifts through the App, we may collect transaction
              details such as the recipient's name, payment amount, and
              transaction history.
            </p>
          </div>

          <div className="text-block">
            <h3>Use of Information</h3>
            <p>
              <br />
              • Providing Services: We use the collected information to
              operate, maintain, and improve the functionality of the App,
              including processing transactions, providing customer support, and
              personalizing your experience.
              <br />
              • Communication: We may use your contact information to send
              you transactional emails, service-related notifications, and
              promotional messages about our App, subject to your preferences
              and applicable laws.
              <br />
              2.3 Aggregated Data: We may aggregate and anonymize the
              information collected from you to generate statistical or
              analytical data, which we may use for research, marketing, or
              other business purposes.
              <br />
              2.4 Legal Compliance: We may use or disclose your information to
              comply with legal obligations, enforce our terms and conditions,
              protect our rights, and respond to requests from government
              authorities.
            </p>
          </div>

          <div className="text-block">
            <h3>Information Sharing</h3>
            <p>
              <br />
              • Service Providers: We may share your information with
              trusted third-party service providers who assist us in delivering
              the App's features and functionalities. These providers are
              contractually obligated to handle your information securely and
              only for the purposes specified by us.
              <br />
              • Business Transfers: In the event of a merger, acquisition,
              or sale of all or a portion of our assets, your information may be
              transferred to the acquiring entity as part of the transaction. We
              will notify you before your information becomes subject to a
              different privacy policy.
              <br />
              • Legal Requirements: We may disclose your information if we
              believe it is necessary to comply with applicable laws,
              regulations, legal processes, or enforceable governmental
              requests, or to protect the rights, property, or safety of the
              App, its users, or others.
              <br />
            </p>
          </div>

          <div className="text-block">
            <h3>Data Security</h3>
            <p>
              <br />• We implement reasonable security measures to protect your
              information from unauthorized access, disclosure, alteration, or
              destruction. However, please note that no method of transmission
              or storage is 100% secure, and we cannot guarantee the absolute
              security of your information.
            </p>
          </div>

          <div className="text-block">
            <h3>Third-Party Links and Services</h3>
            <p>
              <br />• The App may contain links to third-party websites or
              services. This Privacy Policy does not cover the practices of
              these third parties. We encourage you to review the privacy
              policies of those third parties before providing any personal
              information.
            </p>
          </div>

          <div className="text-block">
            <h3>Children's Privacy</h3>
            <p>
              <br />• The App is not intended for use by individuals under the
              age of 16. We do not knowingly collect personal information from
              children. If we become aware that we have collected personal
              information from a child without parental consent, we will take
              steps to remove that information from our servers.
            </p>
          </div>

          <div className="text-block">
            <h3>Your Choices</h3>
            <p>
              <br />• You can review and update your account information within
              the App's settings. You may also opt-out of receiving promotional
              communications from us by following the instructions provided in
              those communications or by contacting us directly.
            </p>
          </div>

          {/* /// testing . */}
          <div className="text-block">
            <h3>Definitions and key terms for this Privacy Policy:</h3>
            <p>
              <br />
              • Cookie: small amount of data generated by a website and saved by
              your web browser. It is used to identify your browser, provide
              analytics, remember information about you such as your language
              preference or login information.
              <br />
              • Company: when this policy mentions "Company," "we," "us," or
              "our," it refers to Share bear that is responsible for your
              information under this Privacy Policy.
              <br />
              • Country: where Share bear or the owners/founders of Share bear
              are based, in this case is USA.
              <br />
              • Customer: refers to the company, organization or person that
              signs up to use the Share bear Service to manage the relationships
              with your consumers or service users.
              <br />
              • Device: any internet connected device such as a phone, tablet,
              computer or any other device that can be used to visit Share bear
              and use the services.
              <br />
              • IP address: Every device connected to the Internet is assigned a
              number known as an Internet protocol (IP) address. These numbers
              are usually assigned in geographic blocks. An IP address can often
              be used to identify the location from which a device is connecting
              to the Internet.
              <br />
              • Personnel: refers to those individuals who are employed by Share
              bear or are under contract to perform a service on behalf of one
              of the parties.
              <br />
              • Personal Data: any information that directly, indirectly, or in
              connection with other information — including a personal
              identification number — allows for the identification or
              identifiability of a natural person
              <br />
              • Service: refers to the service provided by Share bear as
              described in the relative terms (if available) and on this
              platform.
              <br />
              • Third-party service: refers to advertisers, contest sponsors,
              promotional and marketing partners, and others who provide our
              content or whose products or services we think may interest you.
              <br />
              • Website: Share bear's site, which can be accessed via this URL:
              sharebear.gift
              <br />• You: a person or entity that is registered with Share bear
              to use the Services.
            </p>
          </div>

          <div className="text-block">
            <h3>What Information Do We Collect?</h3>
            <p>
              We collect information from you when you visit our service,
              register, place an order, subscribe to our newsletter, respond to
              a survey or fill out a form.
              <br />
              • Name / Username
              <br />
              • Phone Numbers
              <br />
              • Email Addresses
              <br />
              • Mailing Addresses
              <br />
              • Billing Addresses
              <br />• Password
            </p>
          </div>

          <div className="text-block">
            <h3>How Do We Use The Information We Collect?</h3>
            <p>
              Any of the information we collect from you may be used in one of
              the following ways:
              <br />
              • To personalize your experience (your information helps us to
              better respond to your individual needs)
              <br />
              • To improve our service (we continually strive to improve our
              service offerings based on the information and feedback we receive
              from you)
              <br />
              • To improve customer service (your information helps us to more
              effectively respond to your customer service requests and support
              needs)
              <br />
              • To process transactions
              <br />
              • To administer a contest, promotion, survey or other site feature
              <br />• To send periodic emails When do we use customer
              information from third parties? We receive some information from
              the third parties when you contact us. For example, when you
              submit your email address to us to show interest in becoming our
              customer, we receive information from a third party that provides
              automated fraud detection services to us. We also occasionally
              collect information that is made publicly available on social
              media websites. You can control how much of your information
              social media websites make public by visiting these websites and
              changing your privacy settings.
            </p>
          </div>

          <div className="text-block">
            <h3>Do we share the information we collect with third parties?</h3>
            <p>
              We may share the information that we collect, both personal and
              non- personal, with third parties such as advertisers, contest
              sponsors, promotional and marketing partners, and others who
              provide our content or whose products or services we think may
              interest you. We may also share it with our current and future
              affiliated companies and business partners, and if we are involved
              in a merger, asset sale or other business reorganization, we may
              also share or transfer your personal and non-personal information
              to our successors-in-interest. We may engage trusted third party
              service providers to perform functions and provide services to us,
              such as hosting and maintaining our servers and our service,
              database storage and management, e-mail management, storage
              marketing, credit card processing, customer service and fulfilling
              orders for products and services you may purchase through our
              platform. We will likely share your personal information, and
              possibly some non- personal information, with these third parties
              to enable them to perform these services for us and for you. We
              may share portions of our log file data, including IP addresses,
              for analytics purposes with third parties such as web analytics
              partners, application developers, and ad networks. If your IP
              address is shared, it may be used to estimate general location and
              other technographics such as connection speed, whether you have
              visited the service in a shared location, and type of device used
              to visit the service. They may aggregate information about our
              advertising and what you see on the service and then provide
              auditing, research and reporting for us and our advertisers. We
              may also disclose personal and non-personal information about you
              to government or law enforcement officials or private parties as
              we, in our sole discretion, believe necessary or appropriate in
              order to respond to claims, legal process (including subpoenas),
              to protect our rights and interests or those of a third party, the
              safety of the public or any person, to prevent or stop any
              illegal, unethical, or legally actionable activity, or to
              otherwise comply with applicable court orders, laws, rules and
              regulations.
            </p>
          </div>

          <div className="text-block">
            <h3>
              Where and when is information collected from customers and end
              users?
            </h3>
            <p>
              We will collect personal information that you submit to us. We may
              also receive personal information about you from third parties as
              described above.
            </p>
          </div>

          <div className="text-block">
            <h3>How Do We Use Your Email Address?</h3>
            <p>
              By submitting your email address on this app, you agree to receive
              emails from us. You can cancel your participation in any of these
              email lists at any time by clicking on the opt-out link or other
              unsubscribe option that is included in the respective email. We
              only send emails to people who have authorized us to contact them,
              either directly, or through a third party. We do not send
              unsolicited commercial emails, because we hate spam as much as you
              do. By submitting your email address, you also agree to allow us
              to use your email address for customer audience targeting on sites
              like Facebook, where we display custom advertising to specific
              people who have opted-in to receive communications from us. Email
              addressessubmitted only through the order processing page will be
              used for the sole purpose of sending you information and updates
              pertaining to your order. If, however, you have provided the same
              email to us through another method, we may use it for any of the
              purposes stated in this Policy. Note: If at any time you would
              like to unsubscribe from receiving future emails, we include
              detailed unsubscribe instructions at the bottom of each email.
            </p>
          </div>

          <div className="text-block">
            <h3>Could my information be transferred to other countries?</h3>
            <p>
              We are incorporated in USA. Information collected via our website,
              through direct interactions with you, or from use of our help
              services may be transferred from time to time to our offices or
              personnel, or to third parties, located throughout the world, and
              may be viewed and hosted anywhere in the world, including
              countries that may not have laws of general applicability
              regulating the use and transfer of such data. To the fullest
              extent allowed by applicable law, by using any of the above, you
              voluntarily consent to the trans-border transfer and hosting of
              such information.
            </p>
          </div>

          <div className="text-block">
            <h3>Is the information collected through our service secure?</h3>
            <p>
              We take precautions to protect the security of your information.
              We have physical, electronic, and managerial procedures to help
              safeguard, prevent unauthorized access, maintain data security,
              and correctly use your information. However, neither people nor
              security systems are foolproof, including encryption systems. In
              addition, people can commit intentional crimes, make mistakes or
              fail to follow policies. Therefore, while we use reasonable
              efforts to protect your personal information, we cannot guarantee
              its absolute security. If applicable law imposes any non-
              disclaimable duty to protect your personal information, you agree
              that intentional misconduct will be the standards used to measure
              our compliance with that duty.
            </p>
          </div>

          <div className="text-block">
            <h3>Can I update or correct my information?</h3>
            <p>
              The rights you have to request updates or corrections to the
              information we collect depend on your relationship with us.
              Personnel may update or correct their information as detailed in
              our internal company employment policies. Customers have the right
              to request the restriction of certain uses and disclosures of
              personally identifiable information as follows. You can contact us
              in order to (1) update or correct your personally identifiable
              information, (2) change your preferences with respect to
              communications and other information you receive from us, or (3)
              delete the personally identifiable information maintained about
              you on our systems (subject to the following paragraph), by
              cancelling your account. Such updates, corrections, changes and
              deletions will have no effect on other information that we
              maintain, or information that we have provided to thirdparties in
              accordance with this Privacy Policy prior to such update,
              correction, change or deletion. To protect your privacy and
              security, we may take reasonable steps (such as requesting a
              unique password) to verify your identity before granting you
              profile access or making corrections. You are responsible for
              maintaining the secrecy of your unique password and account
              information at all times. You should be aware that it is not
              technologically possible to remove each and every record of the
              information you have provided to us from our system. The need to
              back up our systems to protect information from inadvertent loss
              means that a copy of your information may exist in a non-erasable
              form that will be difficult or impossible for us to locate.
              Promptly after receiving your request, all personal information
              stored in databases we actively use, and other readily searchable
              media will be updated, corrected, changed or deleted, as
              appropriate, as soon as and to the extent reasonably and
              technically practicable. If you are an end user and wish to
              update, delete, or receive any information we have about you, you
              may do so by contacting the organization of which you are a
              customer.
            </p>
          </div>

          <div className="text-block">
            <h3>Sale of Business</h3>
            <p>
              We reserve the right to transfer information to a third party in
              the event of a sale, merger or other transfer of all or
              substantially all of the assets of us or any of its Corporate
              Affiliates (as defined herein), or that portion of us or any of
              its Corporate Affiliates to which the Service relates, or in the
              event that we discontinue our business or file a petition or have
              filed against us a petition in bankruptcy, reorganization or
              similar proceeding, provided that the third party agrees to adhere
              to the terms of this Privacy Policy. Affiliates We may disclose
              information (including personal information) about you to our
              Corporate Affiliates. For purposes of this Privacy Policy,
              "Corporate Affiliate" means any person or entity which directly or
              indirectly controls, is controlled by or is under common control
              with us, whether by ownership or otherwise. Any information
              relating to you that we provide to our Corporate Affiliates will
              be treated by those Corporate Affiliates in accordance with the
              terms of this Privacy Policy.
            </p>
          </div>

          <div className="text-block">
            <h3>How Do We Protect Your Information?</h3>
            <p>
              We implement a variety of security measures to maintain the safety
              of your personal information when you place an order or enter,
              submit, or access your personal information. We offer the use of a
              secure server. All supplied sensitive/credit information is
              transmitted via Secure Socket Layer (SSL) technology and then
              encrypted into our Payment gateway providers database only to be
              accessible by those authorized with special access rights to such
              systems, and are required to keep the information confidential.
              After a transaction, your private information (credit cards,
              social security numbers, financials, etc.) is never kept on file.
              We cannot, however, ensure or warrant the absolute security of any
              information you transmit to us or guarantee that your information
              on the Service may not be accessed, disclosed, altered, or
              destroyed by a breach of any of our physical, technical, or
              managerial safeguards.
            </p>
          </div>

          <div className="text-block">
            <h3>Governing Law</h3>
            <p>
              The laws of USA, excluding its conflicts of law rules, shall
              govern this Agreement and your use of our service. Your use of our
              service may also be subject to other local, state, national, or
              international laws.
            </p>
          </div>

          <div className="text-block">
            <h3>Your Consent</h3>
            <p>
              By using our service, registering an account, or making a
              purchase, you consent to this Privacy Policy.
            </p>
          </div>

          <div className="text-block">
            <h3>Links to Other Websites</h3>
            <p>
              This Privacy Policy applies only to the Services. The Services may
              contain links to other websites not operated or controlled by
              Share bear. We are not responsible for the content, accuracy or
              opinions expressed in such websites, and such websites are not
              investigated, monitored or checked for accuracy or completeness by
              us. Please remember that when you use a link to go from the
              Services to another website, our Privacy Policy is no longer in
              effect. Your browsing and interaction on any other website,
              including those that have a link on our platform, is subject to
              that website's own rules and policies. Such third parties may use
              their own cookies or other methods to collect information about
              you.
            </p>
          </div>

          <div className="text-block">
            <h3>Cookies</h3>
            <p>
              We use "Cookies" to identify the areas of our website that you
              have visited. A Cookie is a small piece of data stored on your
              computer or mobile device by your web browser. We use Cookies to
              personalize the Content that you see on our website. Most web
              browsers can be set to disable the use of Cookies. However, if you
              disable Cookies, you may not be able to access functionality on
              our website correctly or at all. We neverplace Personally
              Identifiable Information in Cookies.
            </p>
          </div>

          <div className="text-block">
            <h3>Payment Details</h3>
            <p>
              In respect to any credit card or other payment processing details
              you have provided us, we commit that this confidential information
              will be stored in the most secure manner possible.
            </p>
          </div>

          <div className="text-block">
            <h3>Information about General Data Protection Regulation (GDPR)</h3>
            <p>
              We may be collecting and using information from you if you are
              from the European Economic Area (EEA), and in this section of our
              Privacy Policy we are going to explain exactly how and why is this
              data collected, and how we maintain this data under protection
              from being replicated or used in the wrong way.
            </p>
          </div>

          <div className="text-block">
            <h3>What is GDPR?</h3>
            <p>
              GDPR is an EU-wide privacy and data protection law that regulates
              how EU residents' data is protected by companies and enhances the
              control the EU residents have, over their personal data.
            </p>
          </div>

          <div className="text-block">
            <h3>What is personal data?</h3>
            <p>
              Any data that relates to an identifiable or identified individual.
              GDPR covers a broad spectrum of information that could be used on
              its own, or in combination with other pieces of information, to
              identify a person. Personal data extends beyond a person's name or
              email address. Some examples include financial information,
              political opinions, genetic data, biometric data, IP addresses,
              physical address, sexual orientation, and ethnicity. The Data
              Protection Principles include requirements such as:
              <br />
              • Personal data collected must be processed in a fair, legal, and
              transparent way and should only be used in a way that a person
              would reasonably expect.
              <br />
              • Personal data should only be collected to fulfil a specific
              purpose and it should only be used for that purpose. Organizations
              must specify why they need the personal data when they collect it.
              <br />
              • Personal data should be held no longer than necessary to fulfil
              its purpose.
              <br />• People covered by the GDPR have the right to access their
              own personal data. They can also request a copy of their data, and
              that their data be updated, deleted, restricted, or moved to
              another organization.
            </p>
          </div>

          <div className="text-block">
            <h3>Why is GDPR important?</h3>
            <p>
              GDPR adds some new requirements regarding how companies should
              protect individuals' personal data that they collect and process.
              It also raises the stakes for compliance by increasing enforcement
              and imposing greater fines for breach. Beyond these facts it's
              simply the right thing to do. At Help Scout we strongly believe
              that your data privacy is veryimportant and we already have solid
              security and privacy practices in place that go beyond the
              requirements of this new regulation.
            </p>
          </div>

          <div className="text-block">
            <h3>
              Individual Data Subject's Rights - Data Access, Portability and
              Deletion
            </h3>
            <p>
              We are committed to helping our customers meet the data subject
              rights requirements of GDPR. We process or store all personal data
              in fully vetted, DPA compliant vendors. We do store all
              conversation and personal data for up to 6 years unless your
              account is deleted. In which case, we dispose of all data in
              accordance with our Terms of Service and Privacy Policy, but we
              will not hold it longer than 60 days. We are aware that if you are
              working with EU customers, you need to be able to provide them
              with the ability to access, update, retrieve and remove personal
              data. We got you! We've been set up as self service from the start
              and have always given you access to your data and your customers
              data. Our customer support team is here for you to answer any
              questions you might have about working with the API.
            </p>
          </div>

          <div className="text-block">
            <h3>California Residents</h3>
            <p>
              The California Consumer Privacy Act (CCPA) requires us to disclose
              categories of Personal Information we collect and how we use it,
              the categories of sources from whom we collect Personal
              Information, and the third parties with whom we share it, which we
              have explained above. We are also required to communicate
              information about rights California residents have under
              California law. You may exercise the following rights:
              <br />
              • Right to Know and Access. You may submit a verifiable request
              for information regarding the: (1) categories of Personal
              Information we collect, use, or share; (2) purposes for which
              categories of Personal Information are collected or used by us;
              (3) categories of sources from which we collect Personal
              Information; and (4) specific pieces of Personal Information we
              have collected about you.
              <br />
              • Right to Equal Service. We will not discriminate against you if
              you exercise your privacy rights.
              <br />• Right to Delete. You may submit a verifiable request to
              close your account and we will delete Personal Information about
              you that we have collected. • Request that a business that sells a
              consumer's personal data, not sell the consumer's personal data.
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us. We
              do not sell the Personal Information of our users. For more
              information about these rights, please contact us.
            </p>
          </div>

          <div className="text-block">
            <h3>California Online Privacy Protection Act (CaIOPPA)</h3>
            <p>
              CaIOPPA requires us to disclose categories of Personal Information
              we collect and how we use it, the categories of sources from whom
              we collectPersonal Information, and the third parties with whom we
              share it, which we have explained above. CaIOPPA users have the
              following rights:
              <br />
              • Right to Know and Access. You may submit a verifiable request
              for information regarding the: (1) categories of Personal
              Information we collect, use, or share; (2) purposes for which
              categories of Personal Information are collected or used by us;
              (3) categories of sources from which we collect Personal
              Information; and (4) specific pieces of Personal Information we
              have collected about you.
              <br />
              • Right to Equal Service. We will not discriminate against you if
              you exercise your privacy rights.
              <br />
              • Right to Delete. You may submit a verifiable request to close
              your account and we will delete Personal Information about you
              that we have collected.
              <br />• Right to request that a business that sells a consumer's
              personal data, not sell the consumer's personal data. If you make
              a request, we have one month to respond to you. If you would like
              to exercise any of these rights, please contact us. We do not sell
              the Personal Information of our users. For more information about
              these rights, please contact us.
            </p>
          </div>

          <div className="text-block">
            <h3>Changes to this Privacy Policy</h3>
            <p>
              <br />• We may update this Privacy Policy from time to time to
              reflect changes in our practices or legal requirements. We will
              notify you of any material updates by posting the revised Privacy
              Policy on our website or through other appropriate communication
              channels. We encourage you to review this Privacy Policy
              periodically.
            </p>
          </div>

          <div className="text-block">
            <h3>Contact Us</h3>
            <p>
              <br />• If you have any questions, concerns, or feedback regarding
              this Privacy Policy or our privacy practices, please contact us at
              [contact email]. We will make reasonable efforts to address your
              inquiry promptly. By using the Share Gifts and Payments App, you
              acknowledge that you have read and understood this Privacy Policy
              and agree to the collection, use, and disclosure of your
              information as described herein. Last updated: Thursday 15th June,
              2023
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default PrivacyPolicy;

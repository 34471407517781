import React from "react";
import Header from "../components/Header/Header";

const AppLayout = ({ children }) => {
  return (
    <div className="gradient-color">
      <Header />
      {children}
    </div>
  );
};

export default AppLayout;

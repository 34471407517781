import React, { useEffect } from "react";
import StaticBanner from "../../components/StaticBanner/StaticBanner";

function ContactUS() {
  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);
  return (
    <main className="main-content">
      <StaticBanner heading="Contact US" subHeading="You can reach us" />
      <section className="content-block-section">
        <div className="card-wrapper">
          <div className="text-block">
            <h3>Introduction</h3>
            <p>
              Sed non dui aliquam, ullamcorper est non, aliquet mauris. Quisque
              lacus ligula, dapibus nec dignissim non, tincidunt vel quam. Etiam
              porttitor iaculis odio.
            </p>
            <p>
              Cras sagittis nunc lectus, in condimentum ligula ornare at. Etiam
              sagittis malesuada nisl. Duis volutpat ligula ante. Sed cursus,
              tortor a pellentesque pulvinar, lorem ipsum gravida elit, id
              posuere elit neque in est. Mauris ex justo, tincidunt at suscipit
              quis, pretium a mi.
            </p>
          </div>

          <div className="text-block">
            <h3>Using our services</h3>
            <p>
              Sed luctus tristique est, non tempor ipsum tincidunt in.
              Vestibulum commodo eu augue nec maximus. Vestibulum vel metus
              varius turpis finibus accumsan in eu ligula. Phasellus semper
              ornare orci, sit amet fermentum neque faucibus id. Vestibulum id
              ante massa.
            </p>
          </div>

          <div className="text-block">
            <h3>Privacy and copyright protection</h3>
            <p>
              Duis ultrices augue id feugiat commodo. Vivamus eu elit convallis,
              posuere neque at, ultricies tortor. Donec eleifend tortor vel
              laoreet convallis.
            </p>
            <p>
              Aliquam fermentum velit nunc, quis tristique neque imperdiet in.
              Etiam pretium fermentum eros vitae scelerisque.
            </p>
            <p>
              Class aptent taciti sociosqu ad litora torquent per conubia
              nostra, per inceptos himenaeos. Etiam dignissim urna eget dictum
              congue.
            </p>
            <p>
              Cras sagittis nunc lectus, in condimentum ligula ornare at. Etiam
              sagittis malesuada nisl. Duis volutpat ligula ante. Sed cursus,
              tortor a pellentesque pulvinar, lorem ipsum gravida elit, id
              posuere elit neque in est. Mauris ex justo, tincidunt at suscipit
              quis, pretium a mi.
            </p>
          </div>

          <div className="text-block">
            <h3>Your content in our services</h3>
            <p>
              Aenean id placerat tortor. Ut vel interdum odio. Mauris vitae
              tortor augue. Sed tincidunt, metus id venenatis dictum, nisi felis
              suscipit neque, pharetra consectetur nibh eros et lorem.
              Suspendisse potenti.
            </p>
            <p>
              Vivamus eu massa gravida, facilisis mauris vel, sodales diam.
              Phasellus at nisi condimentum, bibendum lacus eu, suscipit erat.
              Mauris fringilla laoreet tristique. Sed eleifend tincidunt erat,
              in cursus felis fringilla sit amet. Morbi commodo est dapibus
              metus tincidunt pellentesque.
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ContactUS;

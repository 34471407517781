import React from 'react';
import GiftsMainLayout from '../../components/Gifts/GiftsMainLayout';

const Profile = () => {
  return (
    <div>
        <GiftsMainLayout/>
    </div>
  );
};

export default Profile;

import React, { useEffect, useState } from "react";
import logo from "../../images/brand/logo.svg";
import { Link } from "react-router-dom";
import { useAppContext } from "../../store/appContext";
import { toaster } from "../../libs/utils";

const HomePageHeader = () => {
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const [redirectUrl, setRedirectUrl] = useState("/auth/login");

  const handleSignout = () => {
    userHasAuthenticated(false);
    localStorage.clear();
    toaster("info", "You signed out successfully from sharebear");
  };

  useEffect((_) => {
    if (!isAuthenticated && location?.pathname.indexOf("receiver/")) {
      setRedirectUrl(`/auth/login?redirect=${location?.pathname}`);
    }
  }, []);

  return (
    <header className="main-header">
      <div className="container">
        <div className="header-bar">
          <div className="content-wrapper">
            <div className="brand-logo">
              <Link to="/home">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <nav className="top-nav">
              <ul className="h-list">
                {/* <li className="nav-item  hide-mobile">
                  <Link to="/home">Check out the app</Link>
                </li> */}
                {isAuthenticated ? (
                  <li className="nav-item">
                    <div className="dropdown">
                      <a
                        href="#"
                        // className="dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <i className="fa fa-user"></i>
                      </a>
                      <ul className="dropdown-menu dropdown-menu-right">
                        <li className="dropdown-item">
                          <Link to="/gifts">
                            <i className="fa fa-gift"></i> Sent/Received Gifts
                          </Link>
                        </li>
                        <li className="dropdown-item">
                          <Link to="/update-profile">
                            <i className="fa fa-user"></i> Update Profile
                          </Link>
                        </li>
                        <li className="dropdown-item">
                          <Link to="/balance">
                            <i className="fa fa-coins"></i> Balance
                          </Link>
                        </li>
                        <li className="dropdown-item">
                          <Link to="/funds">
                            <i className="fa fa-plus-square"></i> Add Funds
                          </Link>
                        </li>
                        <li className="dropdown-item">
                          <Link to="/profile">
                            <i className="fa fa-key"></i> Change Password
                          </Link>
                        </li>
                        {/* <li className="dropdown-item">
                          <a href="#">
                            <i className="fa fa-bell"></i> Notification
                          </a>
                        </li> */}
                        <li className="dropdown-item">
                          <a onClick={() => handleSignout()}>
                            <i className="fas fa-sign-out-alt"></i> Sign Out
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                ) : (
                  <li className="nav-item">
                    <Link to={redirectUrl}>Sign in</Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HomePageHeader;

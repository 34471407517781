import axios from 'axios';
import config from '../config';
const baseInstance = axios.create({
  baseURL: config.API_BASE_URL,
});

baseInstance.defaults.headers.common['Accept'] = 'application/json';
baseInstance.defaults.headers.common['Content-Type'] = 'application/json';
baseInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

baseInstance.interceptors.response.use(
  (res) => res.data,
  (err) => {
    if (
      err?.response?.status === 401 
    ) {
        // localStorage.clear();
    }
    throw err;
  }
);

export default baseInstance;
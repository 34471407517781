import React from 'react';
import config from "../../config";

function HavingProblems() {
  return (
    <a href={`mailto:${ config.SUPPORT_EMAIL}`}>Having any problems?</a>
  )
}

export default HavingProblems;

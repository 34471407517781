import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { useAppContext } from "../../store/appContext";
import API from "../../libs/axios";
import Title from "../../AtomicStructure/Title";
import SecondaryTitle from "../../AtomicStructure/SecondaryTitle";
import cashImage from "../../images/cash.png";
import ThirdTitle from "../../AtomicStructure/ThirdTitle";
import SmallTitle from "../../AtomicStructure/SmallTitle";

const AccountBalanceMainContent = () => {
  const { isLoading, setIsLoading, user, setUser, userHasAuthenticated } =
    useAppContext();

  const getUser = async () => {
    try {
      setIsLoading(true);
      const { user } = await API.get("/auth/me");
      if (user) {
        setUser(user);
        userHasAuthenticated(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  return (
    <main className="main-content">
      {user && (
        <div className="container card-flex">
          <div className="gift-sent-card popup-box pt-40 pb-40">
            <div className="white-holder">
              <div className="container text-left">
                <div>
                  <Title
                    text={`${user?.firstname ? user?.firstname : user?.email}!`}
                  />
                </div>
                <div>
                  {/* <Text
                    text={
                      gifts && gifts.length
                        ? "You have unopened Gift Cards and messages."
                        : 'You don"t have any gift cards.'
                    }
                  /> */}
                </div>
              </div>

              <div className="container text-left margt-3 mb-5">
                <div className="mt-5">
                  <div className="row">
                    <div className="col-6">
                      <p className="templateSecondaryTitle mb-0">Account</p>
                    </div>

                    <div className="col-6 text-right">
                      <Link to="/funds" className="btn">
                        Add Funds
                      </Link>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="sentRecievedGiftList">
                      <div className="row">
                        <div className="col-2 p-0">
                          <img
                            src={cashImage}
                            className="sentRecievedIcons"
                            alt="Apple Icon"
                          />
                        </div>
                        <div className="col-10 p-0">
                          <div className="row">
                            <div className="col-12">
                              <ThirdTitle text={"Available Balance"} />
                            </div>
                            <div className="col-10 pr-0">
                              <SmallTitle
                                text={`$ ${user?.tango?.currentBalance}`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <SentRecievedGiftList
                      message={"Available Balance"}
                      amount={`USD ${user?.tango?.currentBalance}`}
                      sender={user?.email}
                      // receiver={order?.recipient?.firstName}
                      image={cashImage}
                    /> */}
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default AccountBalanceMainContent;

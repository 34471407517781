import React, { useState } from "react";
import { useAppContext } from "../../store/appContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import passwordIcon from "../../images/icon/password-icon.svg";
import Input from "../Input/Input";
import API from "../../libs/axios";
import { onError } from "../../libs/errorLib";
import LoaderButton from "../LoaderButton/LoaderButton";
import GoBack from "../GoBack/GoBack";
import { toaster } from "../../libs/utils";

const ProfileMainContent = () => {
  const { isLoading, setIsLoading } = useAppContext();
  const [type, setType] = useState(false);
  const [cType, setCType] = useState(false);

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      currentPassword: Yup.string().required("Password is required"),
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string()
        .label("Confirm Password")
        .required()
        .oneOf(
          [Yup.ref("password"), null],
          "Password and Confirm password should match"
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        const payload = { ...values };
        await API.post("auth/change-pass", payload);

        toaster("success", "Password Updated");
      } catch (e) {
        onError(e);
      } finally {
        setIsLoading(false);
      }
    },
  });
  return (
    <main className="main-content">
      <div className="container">
        <div className="pt-60 pt-30-sm pb-60 pb-30-sm">
          <div className="card-content-wrapper">
            <div className="card-item">
              <div className="card-block-wrapper max-w-370 mb-20">
                <form noValidate onSubmit={formik.handleSubmit}>
                  <div className="card-block-title pt-40 mb-30 ml-0 mr-0">
                    <h1>Change Password</h1>
                  </div>
                  <div className="form-wrapper">
                    <div className="form-group mb-20">
                      <label className="title-info">Current Password</label>
                      <div className="input-item">
                        <Input
                          id="currentPassword"
                          label="Current Password"
                          type={type ? "text" : "password"}
                          name="currentPassword"
                          formik={formik}
                          {...formik.getFieldProps("currentPassword")}
                        />
                        <img
                          onClick={() => setType(!type)}
                          className="clickable"
                          src={passwordIcon}
                          alt="password Icon"
                        />
                      </div>
                    </div>
                    <div className="form-group mb-20">
                      <label className="title-info">Password</label>
                      <div className="input-item">
                        <Input
                          id="password"
                          label="Password"
                          type={cType ? "text" : "password"}
                          name="password"
                          formik={formik}
                          {...formik.getFieldProps("password")}
                        />
                        <img
                          onClick={() => setCType(!cType)}
                          className="clickable"
                          src={passwordIcon}
                          alt="password Icon"
                        />
                      </div>
                    </div>

                    <div className="form-group mb-20">
                      <label className="title-info">Confirm Password {type}</label>
                      <div className="input-item">
                        <Input
                          id="confirmPassword"
                          label="Confirm Password"
                          type={cType ? "text" : "password"}
                          name="confirmPassword"
                          formik={formik}
                          {...formik.getFieldProps("confirmPassword")}
                        />
                        <img
                          onClick={() => setCType(!cType)}
                          className="clickable"
                          src={passwordIcon}
                          alt="password Icon"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="btn-section align-items-center">
                    <GoBack />

                    <LoaderButton
                      className="btn btn-primary"
                      type="submit"
                      buttonText="Change Password"
                      loadingText="loading"
                      isLoading={isLoading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProfileMainContent;

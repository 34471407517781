import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Skeleton } from "antd";
import API from "../../libs/axios";
import MarkupParagraph from "../MarkupParagraph";
import clsx from "clsx";
import { useAppContext } from "../../store/appContext";
import GoBack from "../GoBack/GoBack";
import Loader from "../Loader/Loader";
import { onError } from "../../libs/errorLib";
const DetailedGiftcardMain = ({ title, brandKey, orderId, brandId }) => {
  const history = useHistory();
  const [amount, setAmount] = useState(25);
  const [userBalance, setUserBalance] = useState(0);
  const [item, setItem] = useState();
  const [card, setCard] = useState();
  const [image, setImage] = useState();
  const [amounts, setAmounts] = useState([]);
  const { setOrderId, isAuthenticated } = useAppContext();
  useEffect(() => {
    setOrderId();
  }, []);

  useEffect(async () => {
    const card = await API.get(`/brands/${title}/${brandKey}`);

    if (card && card.brand && card.brand.items[0].valueType === "FIXED_VALUE") {
      let values = card.brand.items.map((item) => item.faceValue);
      setAmounts(values);
      setAmount(values[0]);
    }
    setItem(card.brand.items[0]);
    setImage(card.brand.imageUrls["200w-326ppi"]);
    setCard(card.brand);
    const { user } = await API.get("/auth/me");
    const { tango } = user;
    const { currentBalance = 0 } = tango;
    setUserBalance(currentBalance);
  }, []);

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
    if (orderId) {
      getOrder();
    }
  }, []);

  useEffect(() => {
    setCurrentItem();
  }, [amount]);

  const getOrder = async () => {
    const order = await API.get(`orders/${orderId}`);
    if (order) {
      if (order?.amount) {
        setAmount(order?.amount);
      }
    }
  };
 

  const updateOrder = async () => {
    try {
      if (orderId) {
        const order = await API.put(`orders/${orderId}`, {
          amount: amount,
          utid: item.utid,
          brandId: brandId,
        });
        if (order) {
          history.push(`/send-gift-card/${orderId}`);
        }
      } else {
        const order = await API.post(`orders`, {
          amount: amount,
          utid: item.utid,
          brandId: brandId,
        });
        if (order) {
          history.push(`/send-gift-card/${order._id}`);

          //   history.push(`/delivery-message/${order._id}`);
        }
      }
    } catch (err) {
      onError(err);
    }
  };

  const onIncrement = () => {
    const index = amounts.findIndex((item) => item === amount);
    if (index !== -1) {
      if (index + 1 < amounts.length) {
        setAmount(amounts[index + 1]);
        setCurrentItem();
      }
    }
  };

  const onDecreament = () => {
    const index = amounts.findIndex((item) => item === amount);
    if (index !== -1) {
      if (index - 1 >= 0) {
        setAmount(amounts[index - 1]);
        setCurrentItem();
      }
    }
  };

  const setCurrentItem = () => {
    if (card && card.items && card.items[0].valueType === "FIXED_VALUE") {
      const index = card.items.findIndex((item) => item.faceValue === amount);
      if (index !== -1) {
        setItem(card.items[index]);
      }
    }
  };

  return (
    <main className="main-content">
      <section className="gift-card-section">
        <div className="container">
          <div className="card-content-wrapper giftcard-detail max-w-1070">
            <div className="card-item">
              {card ? (
                <>
                  <GoBack />

                  <div className="gc-detail-title mt-40 mb-30">
                    <h3 className="mb-0">{card.brandName}</h3>
                    <MarkupParagraph text={card.shortDescription} />
                  </div>

                  <div className="category-detail-item">
                    <div className="content-holder">
                      <div className="image-holder">
                        {image ? (
                          <img
                            src={image}
                            alt="card item"
                            className="img-fluid"
                          />
                        ) : (
                          <Skeleton active={true} />
                        )}
                      </div>
                      <div className="content-desc">
                        <MarkupParagraph
                          text={card.description}
                          showMore={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="gc-subtitle">
                    <h4>The perfect gift for</h4>
                    <p>Birthdays Thank you</p>
                  </div>

                  <div className="gift-amount-section">
                    <div className="gc-amount-wrapper">
                      <h3>How much would you like to give?</h3>
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="qunatity-section">
                          <div
                            className="value-button"
                            id="decrease"
                            onClick={() =>
                              amounts && amounts.length > 0
                                ? onDecreament()
                                : setAmount((p) => --p)
                            }
                            value="Decrease Value"
                          >
                            {" "}
                            -{" "}
                          </div>
                          <div className="input-item">
                            <span className="currency">$</span>
                            <input
                              type="number"
                              id="number"
                              value={amount}
                              onChange={(e) =>
                                card.items[0].valueType !== "FIXED_VALUE"
                                  ? setAmount(e.target.value)
                                  : null
                              }
                            />
                          </div>
                          <div
                            className="value-button"
                            id="increase"
                            onClick={() =>
                              amounts && amounts.length > 0
                                ? onIncrement()
                                : setAmount((p) => ++p)
                            }
                            value="Increase Value"
                          >
                            {" "}
                            +
                          </div>
                        </div>
                        {card &&
                          card.items &&
                          card.items[0] &&
                          card.items[0].valueType === "FIXED_VALUE" ? (
                          <div className="quantity-buttons">
                            {amounts &&
                              amounts.map((amountValue, index) => {
                                return (
                                  <div className="btn-item" key={index}>
                                    {" "}
                                    <button
                                      className={clsx(
                                        "btn btn-white",
                                        amountValue === amount ? "active" : ""
                                      )}
                                      onClick={() => setAmount(amountValue)}
                                    >
                                      ${amountValue}
                                    </button>
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          <div className="quantity-buttons">
                            <div className="btn-item">
                              {" "}
                              <button
                                className={clsx(
                                  "btn btn-white",
                                  amount === 25 ? "active" : ""
                                )}
                                onClick={() => setAmount(25)}
                              >
                                $25
                              </button>
                            </div>
                            <div className="btn-item">
                              {" "}
                              <button
                                className={clsx(
                                  "btn btn-white",
                                  amount === 50 ? "active" : ""
                                )}
                                onClick={() => setAmount(50)}
                              >
                                $50
                              </button>
                            </div>
                            <div className="btn-item">
                              {" "}
                              <button
                                className={clsx(
                                  "btn btn-white",
                                  amount === 100 ? "active" : ""
                                )}
                                onClick={() => setAmount(100)}
                              >
                                $100
                              </button>
                            </div>
                            <div className="btn-item">
                              {" "}
                              <button
                                className={clsx(
                                  "btn btn-white",
                                  amount !== 25 &&
                                    amount !== 50 &&
                                    amount !== 100
                                    ? "active"
                                    : ""
                                )}
                              >
                                Other
                              </button>
                            </div>
                          </div>
                        )}
                        

                      
                        {
                         isAuthenticated&&(amount > userBalance || amount < 1) ?
                            <div className="alert alert-warning" role="alert">
                              <p >You have low balance please add funds before sending gift.
                              <strong> <Link to="/funds">Click here..</Link></strong> to add funds.
                              </p>

                            </div>
                            :
                            ""
                        }
                        <div className="submit-btn mb-30">
                          <button
                            className="btn btn-primary"
                            onClick={updateOrder}
                            disabled={isAuthenticated && (amount > userBalance || amount < 1)}
                          >
                            Confirm giftcard amount
                          </button>
                        </div>
                        {/* <p className="text-left">{card.terms}</p> */}
                        <MarkupParagraph text={card.terms} showMore={true} />
                      </form>
                    </div>
                  </div>
                </>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default DetailedGiftcardMain;

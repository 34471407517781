import React from "react";
import { useHistory, useParams } from "react-router";

const UploadedContact = ({ image, name, email }) => {
  const history = useHistory();
  const { orderId } = useParams();

  const selectedContact = (_) => {
    history.push(
      `/delivery-message/${orderId}?contactEmail=${email}&contactName=${name}`
    );
  };

  return (
    <li className="card-item clickable" onClick={(_) => selectedContact()}>
      <div className="icon-holder">
        <img src={image} alt="avatart" className="img-fluid" />
      </div>
      <div className="card-desc">
        <strong className="title">{name}</strong>
        <span className="email">{email}</span>
      </div>
    </li>
  );
};

export default UploadedContact;

import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/brand/logo.svg";
import FooterMenuItem from "../FooterMenuItem/FooterMenuItem";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center flex-grow-1">
          <div className="col-sm-3">
            <div className="footer-logo">
              <Link to="/home">
                <img src={logo} alt="brand logo" className="img-fluid" />
              </Link>
            </div>
          </div>
          <div className="col-sm-9">
            <div>
              <div className="row">
                <FooterMenuItem
                  mainHeading="COMPANY"
                  subHeading={[
                    { text: "About", url: "about-us" },
                    { text: "Contact us", url: "contact-us" },
                  ]}
                />
                <FooterMenuItem
                  mainHeading="GIFTS"
                  subHeading={[
                    { text: "Send Gifts", url: "/choose-gift-card" },
                    { text: "Browse Gifts", url: "/choose-gift-card" },
                  ]}
                />
                <FooterMenuItem
                  mainHeading="WEBSITE"
                  subHeading={[
                    { text: "Terms of Service", url: "terms-of-service" },
                    { text: "Privacy Policy", url: "privacy-policy" },
                    // { text: "FAQs", url: "faqs" },
                  ]}
                />
                {/* <FooterMenuItem
                  mainHeading="FOLLOW"
                  subHeading={[
                    { text: "Terms of Service", url: "#" },
                    { text: "Privacy Policy", url: "#" },
                    // { text: "FAQs", url: "#" },
                  ]}
                  icons={true}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

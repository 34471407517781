import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import routes from "./routes";
import RenderRoute from "./components/RelevantSearchCard/RenderRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import Loader from "./components/Loader/Loader";
import { useAppContext } from "./store/appContext";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  const { isLoading } = useAppContext();
  return (
    <>
      {isLoading && (
        <div className="mainLoader">
          {" "}
          <Loader />{" "}
        </div>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable
        pauseOnHover
      />
      <Router>
        { routes.map((route) => (
          <RenderRoute
            key={route.path}
            Layout={route.layout}
            Component={route.component}
            path={route.path}
            to={route.to}
            CustomRoute={route.CustomRoute}
          />
        ))}
      </Router>
    </>
  );
}

export default App;

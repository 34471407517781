import React from "react";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../store/appContext";
import staticBanner from "../../images/static-banner.svg";
import GoBack from "../GoBack/GoBack";

const StaticBanner = ({heading, subHeading}) => {
  const history = useHistory();
  const { isAuthenticated } = useAppContext();

  return (
    <>
      <section className="content-banner-section">
        <div className="intro-holder">
          <div className="title">{heading}</div>
          <div className="text-info">{subHeading}</div>
        </div>
      </section>
    </>
  );
};

export default StaticBanner;

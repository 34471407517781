import React from "react";
import { Redirect, Route } from "react-router-dom";

const RenderRoute = ({ Layout, Component, path, to, CustomRoute }) => {
  if (path && to) {
    return (
      <Route path={path} exact>
        <Redirect to={to} />
      </Route>
    );
  }

  if (Layout && path && CustomRoute) {
    return (
      <CustomRoute path={path} exact>
        <Layout>
          <Component />
        </Layout>
      </CustomRoute>
    );
  }

  if (Layout && path) {
    return (
      <Route path={path} exact>
        <Layout>
          <Component />
        </Layout>
      </Route>
    );
  }

  if (path) {
    return <Route path={path} component={Component || null} exact />;
  }

  return null;
};

export default RenderRoute;

import React from "react";
import '../scss/atomicStructure/atomic.scss'

const Title = (props) => {

  return (
    <p className="templateTitle">
      {props.text}
    </p>
  );
};

export default Title;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../libs/axios";
import searchIcon from "../../images/icon/search-icon.svg";
import SearchedCard from "../SearchedCard/SearchedCard";
import { useAppContext } from "../../store/appContext";
import RelevantSearchCard from "../RelevantSearchCard/RelevantSearchCard";
import queryString from "query-string";
import GoBack from "../GoBack/GoBack";

const ChooseGiftcardMain = () => {
  const [brands, setBrands] = useState([]);
  const { orderId } = useParams();
  const [searchText, setSearchText] = useState("");
  const { setIsLoading, categories } = useAppContext();
  const [search, setSearch] = useState(false);
  const [category, setCategory] = useState({});
  const [categoryName, setCategoryName] = useState("");
  const [subCategory, setSubCategory] = useState({});
  let { cat } = queryString.parse(location.search);

  useEffect(() => {
    if (categories && categories.length) {
      if (cat) {
        const item = categories.find(
          (category) => category.categoryName.toLowerCase() === cat.toLowerCase()
        );
        if (item) {
          setCategory(item);
          setCategoryName(item.categoryName);

          if (item && item?.subCategories?.length) {
            setSubCategory(item.subCategories[0]);
          } else {
            setSubCategory({});
          }
        }
      } else {
        setCategory(categories[0]);
        setCategoryName(categories[0].categoryName);
        if (categories[0] && categories[0]?.subCategories?.length) {
          setSubCategory(categories[0].subCategories[0]);
        } else {
          setSubCategory({});
        }
      }
    }
  }, [categories, cat]);

  useEffect(() => {
    (async () => {
      const query = {
        category: category.categoryName,
        search: searchText
      };

      if (category && category.categoryName) {
        query.category = category.categoryName;
      }

      if (subCategory && subCategory.categoryName) {
        query.subCategory = subCategory.categoryName;
      }

      setIsLoading(true);

      const data = await API.get(`brands`, { params: query });

      setBrands(data.brands);
      setIsLoading(false);
    })();
  }, [search, category, subCategory]);

  const selectCategory = (e) => {
    if (e.target.value !== "") {
      const cat = categories.find(
        (item) => item.categoryName == e.target.value
      );

      if (cat) {
        setCategory(cat);
        setCategoryName(cat.categoryName);
        if (cat && cat.subCategories && cat.subCategories.length) {
          setSubCategory(cat.subCategories[0]);
        } else {
          setSubCategory({});
        }
      }
    } else {
      setCategoryName("");
      setCategory({});
      setSubCategory({});
    }
  };

  const searchFormSubmit = (e) => {
    e.preventDefault();
    handleSearch();
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setSearch((prev) => !prev);
    }
  };

  return (
    <main className="main-content">
      <section className="gift-card-section">
        <div className="container">
          <div className="card-content-wrapper choose-giftcard max-w-1070">
            <div className="card-item">
              <div className="card-holder">
              <GoBack />

                <h2 className="mt-40 mb-40">Choose a giftcard</h2>
                <div className="search-gift-card search-gift-wrapper no-padding">
                  <div className="search-form mb-20">
                    <div className="row m-0">
                      <div className="col-md-7 col-sm-4 p-0">
                        <div className="search-item">
                          <a href="#" className="search-btn">
                            <img src={searchIcon} alt="Icon" />
                          </a>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search for gift cards"
                            value={searchText}
                            onKeyDown={_handleKeyDown}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-4 p-0">
                        <div className="select-item">
                          <select
                            className="form-control"
                            onChange={(e) => selectCategory(e)}
                            value={categoryName}
                          >
                            <option value="">Select Category</option>
                            {categories &&
                              categories.map((category) => (
                                <option
                                  key={category._id}
                                  value={category.categoryName}
                                >
                                  {category.categoryName}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 p-0">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => setSearch((prev) => !prev)}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="relevant-searches mb-30">
                    <div className="relevant-search-wrapper">
                      {category.subCategories &&
                        category.subCategories.map((card, i) => (
                          <RelevantSearchCard
                            handleRelevantClick={() => {
                              setSubCategory(card);
                            }}
                            key={i}
                            title={card.categoryName}
                            selected={
                              subCategory.categoryName === card.categoryName
                                ? true
                                : false
                            }
                          />
                        ))}
                      <RelevantSearchCard
                        handleRelevantClick={() => {
                          setSubCategory(null);
                          setCategory({});
                          setCategoryName("");
                        }}
                        title={"See All"}
                        selected={subCategory === null ? true : false}
                      />
                    </div>
                  </div>
                  <div className="category-searched-list">
                    <div className="row">
                      {brands &&
                        brands.map((card) => (
                          <SearchedCard
                            image={card.imageUrls["1200w-326ppi"]}
                            title={card.brandName}
                            key={card.brandKey}
                            brandKey={card.brandKey}
                            text={card.shortDescription}
                            orderId={orderId}
                            brandId={card.id}
                            short={true}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ChooseGiftcardMain;

import React, { useEffect, useState } from "react";
import questionIcon from "../../images/icon/question-icon.svg";
import csvIcon from "../../images/icon/csv-large-icon.svg";
import HavingProblems from "../HavingProblems/HavingProblems";
import ReactFileReader from "react-file-reader";
import { parseContacts } from "../../libs/utils";
import { useAppContext } from "../../store/appContext";
import GoBack from "../GoBack/GoBack";
import { useHistory, useParams } from "react-router";

const UploadContactsMain = () => {
  const { contacts, setContacts } = useAppContext();
  const { orderId } = useParams();
  const history = useHistory();

  useEffect((_) => {
    if (contacts || contacts.length) {
      setContacts([]);
    }
  }, []);

  const handleFiles = (files) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const contact = parseContacts(reader.result);
      if (contact && contact.length) {
        setContacts(contact);
      }
    };
    reader.readAsText(files[0]);
  };

  const displayProgress = () => {
    if (contacts && contacts.length) {
      return (
        <div className="card-desc">
          <ul className="v-list upload-list-nav">
            <li>
              <div className="file-progress-info h-list">
                <div className="file-progress">
                  <span>Contact list</span>
                  <strong>100%</strong>
                </div>
                <div className="icon">
                  <i className="fas fa-check-circle"></i>
                </div>
              </div>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: "100%" }}
                >
                  <span className="sr-only">100</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      );
    }
    return "";
  };

  return (
    <main className="main-content">
      <div className="container">
        <div className="pt-60 pt-30-sm pb-60 pb-30-sm">
          <div className="card-content-wrapper">
            <div className="card-item">
              <div className="card-block-wrapper mb-60">
                <div className="card-block-title pt-40 mb-30">
                  <h1 className="text-center mb-60 mb-30-sm">
                    Upload your contacts
                  </h1>
                </div>
                <nav className="upload-file-wrapper max-w-370">
                  <ul className="v-list upload-list-item">
                    <li>
                      <div
                        className="card-upload"
                        data-toggle="collapse"
                        href="#csvFileUpload"
                        aria-expanded="false"
                      >
                        <div className="h-list">
                          <div className="card-item">
                            <svg
                              width="22"
                              height="24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g filter="url(#filter0_d)" fill="currentColor">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M4 4a4 4 0 014-4h6a4 4 0 014 4v8a4 4 0 01-4 4H8a4 4 0 01-4-4V4zm2-2h10v7H6V2zm1.196 11.932c.224.088.46.132.71.132.207 0 .401-.029.584-.086.184-.057.335-.126.454-.206l-.372-.752a.858.858 0 01-.274.132c-.11.032-.222.048-.338.048-.133 0-.258-.021-.374-.064a.988.988 0 01-.304-.178.855.855 0 01-.204-.27.766.766 0 01-.074-.336c0-.121.025-.233.074-.336a.833.833 0 01.204-.268.968.968 0 01.304-.18 1.154 1.154 0 01.712-.014.83.83 0 01.274.13l.372-.752a1.698 1.698 0 00-.466-.208 1.967 1.967 0 00-1.282.05c-.223.088-.42.21-.592.368-.17.157-.305.34-.404.548a1.522 1.522 0 00-.148.664c0 .236.049.457.146.664.099.205.234.387.406.544.172.157.37.28.592.37zm3.025.098c.13.023.265.034.406.034.267 0 .497-.042.692-.126a.998.998 0 00.45-.364.997.997 0 00.16-.57.938.938 0 00-.064-.362.774.774 0 00-.194-.274 1.522 1.522 0 00-.322-.228 6.03 6.03 0 00-.452-.22l-.176-.078a.903.903 0 01-.116-.06.208.208 0 01-.062-.06.164.164 0 01-.018-.078c0-.045.013-.083.038-.112a.223.223 0 01.108-.068.52.52 0 01.168-.024 1.398 1.398 0 01.446.09c.078.028.15.062.22.102l.348-.708a1.616 1.616 0 00-.312-.148 2.407 2.407 0 00-.368-.1 1.896 1.896 0 00-.356-.036c-.175 0-.333.029-.476.086a1.135 1.135 0 00-.364.232c-.101.097-.18.207-.234.33a.91.91 0 00-.082.378c0 .093.014.181.042.264.03.083.07.16.124.23.055.07.12.135.196.194.078.057.164.109.258.154l.316.152c.087.043.157.082.21.118a.389.389 0 01.116.112.229.229 0 01.038.132.22.22 0 01-.036.128.21.21 0 01-.104.074.509.509 0 01-.168.024c-.072 0-.146-.009-.224-.026a1.655 1.655 0 01-.228-.07 2.054 2.054 0 01-.412-.216l-.436.748c.056.045.127.09.214.136a2.269 2.269 0 00.654.21zm1.829-3.33l1.384 3.3h.688l1.384-3.3h-.992l-.736 1.976-.736-1.976h-.992z"
                                />
                                <path d="M8.381 6l1.13-1.612-.94-1.383h.95l.48.723.478-.723h.952l-.94 1.383L11.618 6h-.987L10 5.082 9.37 6H8.38zM13 5h2v1h-2zM13 7h2v1h-2zM10 7h2v1h-2zM7 7h2v1H7zM13 3h2v1h-2z" />
                              </g>
                              <defs>
                                <filter
                                  id="filter0_d"
                                  x="0"
                                  y="0"
                                  width="22"
                                  height="24"
                                  filterUnits="userSpaceOnUse"
                                  color-interpolation-filters="sRGB"
                                >
                                  <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                  />
                                  <feColorMatrix
                                    in="SourceAlpha"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  />
                                  <feOffset dy="4" />
                                  <feGaussianBlur stdDeviation="2" />
                                  <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                  <feBlend
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow"
                                  />
                                  <feBlend
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow"
                                    result="shape"
                                  />
                                </filter>
                              </defs>
                            </svg>
                            <span>Upload CVS File</span>
                          </div>
                          <div className="icon-holder" title="upload csv with contacts">
                            <img
                              src={questionIcon}
                              alt="icon"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="collapse" id="csvFileUpload">
                        <div className="card card-body">
                          <div className="drag-wrapper">
                            <div className="upload-files files">
                              <ReactFileReader
                                fileTypes={[".csv", ".xlsx", 'xls']}
                                handleFiles={handleFiles}
                                className="form-control"
                              >
                                <input />
                              </ReactFileReader>
                              <div className="content">
                                <div className="mb-20">
                                  <img
                                    src={csvIcon}
                                    alt="icon"
                                    className="img-fluid"
                                  />
                                </div>
                                <span>
                                  {/* <strong>Drag</strong> or{" "} */}
                                  <strong>Click</strong> to upload your files
                                  here
                                </span>
                              </div>
                            </div>
                          </div>
                          {!contacts?.length && (
                            <div className="card-desc">
                              <h5>Uploaded Files</h5>
                              <p>
                                No file uploaded yet. Please upload a CSV or XLS
                                file.
                              </p>
                            </div>
                          )}
                          {displayProgress()}
                        </div>
                      </div>
                    </li>
                    {/* <li>
                      <div
                        className="card-upload"
                        data-toggle="collapse"
                        href="#gmailContactUpload"
                        aria-expanded="false"
                      >
                        <div className="h-list">
                          <div className="card-item">
                            <svg
                              width="16"
                              height="16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.363 6.826v2.547h3.63c-.474 1.544-1.764 2.648-3.63 2.648a4.022 4.022 0 110-8.042c.999 0 1.911.366 2.614.968l1.876-1.876a6.667 6.667 0 10-4.49 11.595c5.598 0 6.833-5.233 6.284-7.831l-6.284-.009z"
                                fill="currentColor"
                              />
                            </svg>
                            <span>Upload Gmail contacts</span>
                          </div>
                          <div className="icon-holder">
                            <img
                              src={questionIcon}
                              alt="icon"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="collapse" id="gmailContactUpload">
                        <div className="card card-body">
                          <div className="drag-wrapper">
                            <div className="upload-files files">
                              <input
                                type="file"
                                className="form-control"
                                multiple=""
                              />
                              <div className="content">
                                <div className="mb-20">
                                  <img
                                    src="images/icon/csv-large-icon.svg"
                                    alt="icon"
                                    className="img-fluid"
                                  />
                                </div>
                                <span>
                                  <strong>Drag</strong> or{" "}
                                  <strong>upload</strong> your files here
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="card-desc">
                            <ul className="v-list upload-list-nav">
                              <li>
                                <div className="file-progress-info h-list">
                                  <div className="file-progress">
                                    <span>Contact_list</span>
                                    <strong>100%</strong>
                                  </div>
                                  <div className="icon">
                                    <i className="fas fa-check-circle"></i>
                                  </div>
                                </div>
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: "100%" }}
                                  >
                                    <span className="sr-only">100</span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div
                        className="card-upload"
                        data-toggle="collapse"
                        href="#appleContactUpload"
                        aria-expanded="false"
                      >
                        <div className="h-list">
                          <div className="card-item">
                            <svg
                              width="16"
                              height="16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.25 11.12c-.347.766-.512 1.108-.956 1.785-.621.946-1.497 2.125-2.58 2.133-.965.008-1.213-.629-2.521-.618-1.308.006-1.58.63-2.545.62-1.084-.01-1.913-1.073-2.535-2.016-1.737-2.647-1.92-5.749-.847-7.399.76-1.17 1.962-1.857 3.09-1.857 1.15 0 1.873.631 2.823.631.922 0 1.484-.633 2.813-.633 1.005 0 2.068.548 2.828 1.493-2.485 1.362-2.081 4.911.43 5.86zM9.982 2.71c.484-.621.85-1.497.717-2.39-.789.054-1.711.558-2.25 1.21-.489.594-.893 1.477-.736 2.33.861.029 1.752-.485 2.27-1.15z"
                                fill="currentColor"
                              />
                            </svg>
                            <span>Upload Apple contacts</span>
                          </div>
                          <div className="icon-holder">
                            <img
                              src={questionIcon}
                              alt="icon"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="collapse" id="appleContactUpload">
                        <div className="card card-body">
                          <div className="drag-wrapper">
                            <div className="upload-files files">
                              <input
                                type="file"
                                className="form-control"
                                multiple=""
                              />
                              <div className="content">
                                <div className="mb-20">
                                  <img
                                    src="images/icon/csv-large-icon.svg"
                                    alt="icon"
                                    className="img-fluid"
                                  />
                                </div>
                                <span>
                                  <strong>Drag</strong> or{" "}
                                  <strong>upload</strong> your files here
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="card-desc">
                            <h5>Uploaded Files</h5>
                            <p>
                              No file uploaded yet. Please upload apple
                              contacts.
                            </p>
                          </div>
                        </div>
                      </div>
                    </li> */}
                  </ul>
                </nav>
              </div>

              <div className="btn-section align-items-center max-w-370">
                <GoBack />
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => history.push(`/search-recipient/${orderId}`)}
                    disabled={!contacts || contacts.length === 0}
                  >
                    Next
                  </button>
                </div>
              </div>
              <div className="info-text">
                <HavingProblems />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default UploadContactsMain;

import React, { useEffect, useState } from "react";
import API from "../../libs/axios";
import { useAppContext } from "../../store/appContext";
import Title from "../../AtomicStructure/Title";
import Text from "../../AtomicStructure/Text";
import SecondaryTitle from "../../AtomicStructure/SecondaryTitle";
import SentRecievedGiftList from "../SentRecievedGiftList/SentRecievedGiftList";

const GiftsMainLayout = () => {
  const [gifts, setGifts] = useState(null);
  const [receivedGifts, setReceivedGifts] = useState(null);
  const [sentGifts, setSentGifts] = useState([]);
  const [finalized, setFinalized] = useState([]);

  const [loading, setLoading] = useState(false);

  const { setOrderId, user } = useAppContext();
  useEffect(async () => {
    setOrderId();
    getOrder();
    // getSentOrder();
  }, []);

  useEffect(async () => {
    // setFinalized(gifts?.filter((order) => gifts?.status === "FINALIZED"));
  }, [gifts]);

  const getOrder = async () => {
    setLoading(true)
    try {
      const giftsResponse = await API.get(`orders/my`);
      if (giftsResponse) {
        setGifts(giftsResponse);
        const receivedOrders = giftsResponse?.filter((gift) => gift?.recipient?.email === user.email && gift?.status === 'SENT');
        const receivedOrdersOpened = giftsResponse?.filter((gift) => gift?.recipient?.email === user.email && gift?.status === 'FINALIZED');
        setFinalized(receivedOrdersOpened);
        setReceivedGifts(receivedOrders);
      }
      await getSentOrder();
    } catch (err) {
    } finally {
      setLoading(false)
    }
  };

  const getSentOrder = async () => {
    setLoading(true)
    try {
      const giftsResponse = await API.get(`orders/sent`);
      if (giftsResponse) {
        setSentGifts(giftsResponse);
        setGifts([...gifts,...giftsResponse]);
        const sentOrders = giftsResponse;
        //?.filter((gift) => gift?.status);
      }
    } catch (err) {
    } finally {
      setLoading(false)
    }
  };


  return (
    <main className="main-content">
      <div className="container card-flex">
        <div className="gift-sent-card popup-box pt-40 pb-40">
          <div className="white-holder">
            <div className="container text-left">
              <div>
                <Title text={`Hey ${user?.firstname ? user?.firstname : user?.email}!`} />
              </div>
              <div>
                <Text
                  text={
                    gifts && gifts.length
                      ? "You have unopened Gift Cards and messages."
                      : 'You don"t have any gift cards.'
                  }
                />
              </div>
            </div>

            <div className="container text-left margt-3">
              <div className="mt-5 mb-22">
                <SecondaryTitle text="Received" />
                {
                  !loading ?
                    receivedGifts?.length ?
                      receivedGifts
                        ? receivedGifts?.map((order) => {
                          return (
                            <div className="mt-5">
                              <SentRecievedGiftList
                                message={order?.message}
                                amount={order?.amount}
                                sender={order?.sender?.firstName}
                                link={order?.status === 'SENT' ?  `/receiver-accept-gc/${order?._id}` : null}
                                // receiver={order?.recipient?.firstName}
                                image={Object.values(order?.brandId?.imageUrls)[0]}
                              />
                            </div>
                          )
                        })
                        : ""
                      :
                      "You have not received any gift card yet."
                    : ""}
              </div>

              <div className="mt-5 mb-22">
                <SecondaryTitle text="Sent" />
                {
                  !loading ?
                    sentGifts?.length ?
                      sentGifts
                        ? sentGifts?.map((order) => {
                          return (
                            <div className="mt-5">
                              <SentRecievedGiftList
                                message={order?.message}
                                amount={order?.amount}
                                sender={order?.sender?.firstName}
                                receiver={order?.recipient?.firstName}
                                image={Object.values(order?.brandId?.imageUrls)[0]}
                              />
                            </div>
                          )

                        })
                        : ""
                      :
                      "You have not sent any gift card yet."
                    : ""}
              </div>

              <div className="mt-5">
                <SecondaryTitle text="Accepted Gift Cards" />
                {!loading ?
                  finalized?.length ?
                    gifts
                      ? finalized?.map((order) => {
                        return order?.status == "FINALIZED" ? (
                          <div className="mt-5">
                            <SentRecievedGiftList
                              message={order?.message}
                              amount={order?.amount}
                              sender={order?.sender?.firstName}
                              image={Object.values(order?.brandId?.imageUrls)[0]}
                            />
                          </div>
                        ) : (
                          ""
                        );
                      })
                      : ""
                    :
                    "You have not recieved any gift card yet." : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default GiftsMainLayout;

import React from "react";

export default ({ formik, type, name, label, groupAs, ...rest }) => {
  return (
    <>
      <input
        placeholder={label}
        type={type}
        className={`form-control${
          formik.errors[name] && formik.touched[name] ? " is-invalid" : ""
        }`}
        {...rest}
      />

      {formik.errors[name] && (
        <div className="invalid-feedback">{formik.errors[name]}</div>
      )}
    </>
  );
};

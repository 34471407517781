import React from "react";
import { useParams } from "react-router-dom";
import DetailedGiftcardMain from "../../components/DetailedGiftcardMain/DetailedGiftcardMain";

const DetailedGiftCard = () => {
  const { title, brandKey, orderId, brandId } = useParams();
  return (
    <DetailedGiftcardMain
      title={title}
      brandKey={brandKey}
      orderId={orderId}
      brandId={brandId}
    />
  );
};

export default DetailedGiftCard;

import React from "react";
import '../scss/atomicStructure/atomic.scss'

const SmallText = (props) => {

  return (
    <p className="templateSmallText mb-0">
      {props.text}
    </p>
  );
};

export default SmallText;

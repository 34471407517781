import React from "react";
import MarkupParagraph from "../MarkupParagraph";
import { useHistory } from "react-router-dom";

const SearchedCard = ({
  image,
  title,
  text,
  brandKey,
  orderId,
  brandId,
  setBrand,
  selected,
  recevier,
}) => {
  const history = useHistory();
  const navigate = () => {
    if (orderId) {
      history.push(
        `/detailed-gift-card/${title}/${brandKey}/${brandId}/${orderId}`
      );
    } else {
      history.push(`/detailed-gift-card/${title}/${brandKey}/${brandId}`);
    }
  };
  return (
    <div className="col-sm-4">
      <div className="content-holder">
        <div className="image-holder">
          {selected?.id === brandId && (
            <i
              className="fa fa-check"
              style={{
                position: "absolute",
                left: "24px",
                top: "6px",
                fontSize: "24px",
                color: "white",
              }}
              aria-hidden="true"
            ></i>
          )}
          <img
            onClick={recevier ? setBrand : navigate}
            src={image}
            alt="Gift Card image"
            className="img-fluid clickable"
          />
        </div>
        <div className="content-desc">
          <h5 className="clickable" onClick={recevier ? setBrand : navigate}>
            {title}
          </h5>
          <MarkupParagraph text={text} showMore={true} lines={3} />
        </div>
      </div>
    </div>
  );
};

export default SearchedCard;

import React from "react";
// import InstaSVG from "../../images/intsta.svg";
// import Facebook from "../../images/facebook.svg";
// import Twitter from "../../images/twitter.svg";
import { Link } from "react-router-dom";

const FooterMenuItem = ({ mainHeading, subHeading, icons }) => {
  return (
    <div className="col-sm-3">
      <nav className="footer-menu-item">
        <h5 className="mt-16 mt-md-0">{mainHeading}</h5>
        <ul className="v-list">
          {icons === undefined &&
            subHeading.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={item.url}>{item.text}</Link>
                </li>
              );
            })}
          {/* {icons == true && (
            <>
              <li>
                <div>
                  <a href={"#"}>
                    <img src={InstaSVG} alt="icon" />
                  </a>
                </div>
              </li>
              <li>
                <div>
                  <a href={"#"}>
                    <img src={Facebook} alt="icon" />
                  </a>
                </div>
              </li>
              <li>
                <div>
                  <a href={"#"}>
                    <img src={Twitter} alt="icon" />
                  </a>
                </div>
              </li>
            </>
          )} */}
        </ul>
      </nav>
    </div>
  );
};

export default FooterMenuItem;

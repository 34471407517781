import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppContext } from "../../store/appContext";
import emailIcon from "../../images/icon/email-icon.svg";
import passwordIcon from "../../images/icon/password-icon.svg";
import Input from "../Input/Input";
import API from "../../libs/axios";
import { onError } from "../../libs/errorLib";
import LoaderButton from "../LoaderButton/LoaderButton";
import GoBack from "../GoBack/GoBack";
import config from "../../config";
import { toaster } from "../../libs/utils";
import queryString from "query-string";
import WelcomeScreen from "../../pages/WelcomeScreen/WelcomeScreen";

const SignUpMainContent = ({ location, history , setCurrentStep}) => {
  const {
    isLoading,
    setIsLoading,
    setUser,
    setToken,
    setRedirect,
  } = useAppContext();
  const [resend, setResend] = useState(false);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
  const [type, setType] = useState(false);

  let params = queryString.parse(location?.search);

  useEffect(() => {
    setRedirect(params?.redirect);
  }, []);
  const formik = useFormik({
    initialValues: {
      firstName:"",
      lastName: "",
      email: "",
      password: "",
      termsCheckbox: false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({  
      firstName: Yup.string()
      .required("First Name is required")
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .max(40),
  lastName: Yup.string()
      .required("Last Name is required")
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .max(40),   
      email: Yup.string()
        .email("Email is not valid")
        .required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .label("Password")
        .min(6),
      termsCheckbox: Yup.boolean().required("Terms are required to be checked"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      if (!values.termsCheckbox) {
        toaster("error", "Please agree to terms and services");
        setIsLoading(false);
        return;
      }

      try {
        const payload = { ...values ,
          firstName: values.firstName,
          lastName: values.lastName,
         };
        payload.confirm_password = payload.password;
        const data = await API.post(`auth/register`, payload);
        if (data) {
          setUser(data.user);
          setToken(data.token);
          setShowWelcomeScreen(true);
          if(setCurrentStep) {
            setCurrentStep(2);
          }
          toaster("info", "Please follow instructions sent to you by email");
        }
      } catch (e) {
        onError(e);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const resendFormik = useFormik({
    initialValues: {
      email: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Email is not valid")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);

      try {
        const payload = { ...values };
        const data = await API.post(`auth/resend-email`, payload);
        if (data) {
          toaster(
            "info",
            "If you are a user of the system, you will receive an email on the given email address"
          );
        }
      } catch (e) {
        onError(e);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      {!showWelcomeScreen ? (
        <>
          <main className="main-content">
            <div className="container">
              <div className="pt-60 pt-30-sm pb-60 pb-30-sm">
                <div className="card-content-wrapper">
                  <div className="card-item">
                    <div className="btn-holder text-right">
                      <span>
                        Already have an account?{" "}
                        <Link
                          to={
                            params.redirect
                              ? `/auth/login?redirect=${params.redirect}`
                              : "/auth/login"
                          }
                        >
                          Log in
                        </Link>
                      </span>
                    </div>

                    <div className="card-block-wrapper max-w-370 mb-20">
                      {resend ? (
                        <>
                          <form onSubmit={resendFormik.handleSubmit}>
                            <div className="card-block-title pt-40 mb-30 ml-0 mr-0">
                              <h1>Resend Verification Email</h1>
                            </div>
                            <div className="form-wrapper">
                              <div className="form-group mb-20">
                                <label className="title-info">Your email</label>
                                <div className="input-item">
                                  <Input
                                    id="email"
                                    label="Johnsmith@gmail.com"
                                    type="email"
                                    name="email"
                                    formik={resendFormik}
                                    {...resendFormik.getFieldProps("email")}
                                  />
                                  <img src={emailIcon} alt="email Icon" />
                                </div>
                              </div>
                            </div>

                            <div className="btn-section align-items-center">
                              <GoBack />

                              <LoaderButton
                                className="btn btn-primary"
                                type="submit"
                                buttonText="Resend verification email"
                                loadingText="loading"
                                isLoading={isLoading}
                              />
                            </div>
                          </form>
                          <a href="#" onClick={() => setResend(false)}>
                            <span>Click here to register</span>
                          </a>
                        </>
                      ) : (
                        <>
                          <form onSubmit={formik.handleSubmit}>
                            <div className="card-block-title pt-40 mb-30 ml-0 mr-0">
                              <h1>Become a ShareBear</h1>
                            </div>
                            <div className="social-btn">
                              <button
                                className="btn btn-white btn-block"
                                type="button"
                                onClick={() =>
                                  window.open(
                                    `${config.API_BASE_URL}/auth/google`,
                                    "_self"
                                  )
                                }
                              >
                                <i className="fab fa-google"></i>
                                <span className="ml-10">
                                  Connect with Google
                                </span>
                              </button>
                            </div>

                            <div className="divider-text">
                              <span>or</span>
                            </div>

                            <div className="form-wrapper">
                            <div className="form-item mb-10 col-sm-12">
                                                <div className="form-group">
                                                    <label className="title-info">First Name</label>
                                                    <div className="input-item">
                                                        <Input
                                                            type="text"
                                                            name="firstName"
                                                            id="firstName"
                                                            placeholder="First Name"
                                                            formik={formik}
                                                            {...formik.getFieldProps("firstName")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-item mb-10 col-sm-12">
                                                <div className="form-group">
                                                    <label className="title-info">Last Name</label>
                                                    <div className="input-item">
                                                        <Input
                                                            type="text"
                                                            name="lastName"
                                                            id="lastName"
                                                            placeholder="Last Name"
                                                            formik={formik}
                                                            {...formik.getFieldProps("lastName")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                              <div className="form-group mb-20">
                                <label className="title-info">Your email</label>
                                <div className="input-item">
                                  <Input
                                    id="email"
                                    label="Johnsmith@gmail.com"
                                    type="email"
                                    name="email"
                                    formik={formik}
                                    {...formik.getFieldProps("email")}
                                  />
                                  <img src={emailIcon} alt="email Icon" />
                                </div>
                              </div>

                              <div className="form-group mb-20">
                                <label className="title-info">
                                  Create password
                                </label>
                                <div className="input-item">
                                  <Input
                                    id="password"
                                    label="Enter your password here"
                                    type={type ? "text" : "password"}
                                    name="password"
                                    formik={formik}
                                    {...formik.getFieldProps("password")}
                                  />
                                  <img
                                    className="clickable"
                                    src={passwordIcon}
                                    onClick={() => setType(!type)}
                                    alt="password Icon"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="apply-conditions mb-20">
                              <label className="custom-checkbox">
                                <Input
                                  id="termsCheckbox"
                                  type="checkbox"
                                  name="termsCheckbox"
                                  formik={formik}
                                  checked={formik.values.termsCheckbox}
                                  {...formik.getFieldProps("termsCheckbox")}
                                />
                                <span>
                                  By signing up, you agree to our privacy policy
                                  and user terms agreement
                                </span>
                              </label>
                            </div>

                            <div className="btn-section align-items-center pb-20">
                              <GoBack />

                              <LoaderButton
                                className="btn btn-primary"
                                type="submit"
                                buttonText="Create your account"
                                loadingText="loading"
                                isLoading={isLoading}
                              />
                            </div>
                            <a href="#" onClick={() => setResend(true)}>
                              <span>
                                Click here to resend verification e-mail
                              </span>
                            </a>
                          </form>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      ) : (
        <WelcomeScreen
          setResend={setResend}
          setShowWelcomeScreen={setShowWelcomeScreen}
        />
      )}
    </>
  );
};

// export default withRouter(SignUpMainContent);
export default SignUpMainContent;
import React from "react";
import verifiedEmail from "../../images/icon/verified-email.svg";
import { useAppContext } from "../../store/appContext";
import { useHistory } from "react-router-dom";
import HavingProblems from "../../components/HavingProblems/HavingProblems";
import GoBack from "../../components/GoBack/GoBack";

const EmailVerified = () => {
  const { orderId } = useAppContext();
  const history = useHistory();
  return (
    <main className="main-content">
      <div className="container card-flex">
        <div className="pt-60 pt-30-sm pb-60 pb-30-sm flex-grow-1">
          <div className="card-content-wrapper card-content-box">
            <div className="card-item">
              <div className="info-text text-right mb-50 max-w-370">
                <HavingProblems />
              </div>

              <div className="card-block-wrapper popup-box max-w-370 mb-20">
                <div className="icon-holder">
                  <img src={verifiedEmail} alt="Icon" className="img-fluid" />
                </div>
                <div className="card-block-title mb-30">
                  <h1 className="text-center mb-10">Email Verified!</h1>
                  <p className="text-center">
                    Welcome to ShareBear! You’re ready to start sharing.
                  </p>
                </div>

                <div className="btn-section align-items-center">
                <GoBack />

                  {orderId && (
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        history.push(
                          orderId
                            ? `/payment-method/${orderId}`
                            : "/choose-gift-card"
                        )
                      }
                    >
                      Continue to send gift
                    </button>
                  )}
                  {!orderId && (
                    <button
                      className="btn btn-primary"
                      onClick={() => history.push(`/funds`)}
                    >
                     Get Started 
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EmailVerified;

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Confetti from "../Confetti/Confetti";
import API from "../../libs/axios";
import Loader from "../Loader/Loader";

const ReceiverHomepageMain = () => {
  const history = useHistory();
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);

  useEffect(async () => {
    if (orderId) {
      getOrder();
    }
  }, []);

  const getOrder = async () => {
    const order = await API.get(`orders/${orderId}`);
    if (order) {
      setOrder(order);
    } else {
        history
    }
  };
  return (
    <main className="main-content">
      <Confetti receiver={true} />
      <div className="container">
        <div className="reciever-content-wrapper reciever-content-wrapper-main">
          <div className="card-item mb-30">
            {!order ? (
              <Loader />
            ) : (
              <div className="card-holder" style={{ zIndex: 20 }}>
                <div className="mb-50 main-title">
                  {order?.sender.firstName} has sent you a message and a gift!
                </div>

                <button
                  className="btn btn-rounded btn-primary"
                  onClick={() => {
                    if (order && !order.message && !order.video) {
                      history.push(`/receiver-accept-gc/${orderId}`);
                    } else {
                      history.push(`/receiver-video-text-message/${orderId}`);
                    }
                  }}
                >
                  View message and gift!
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default ReceiverHomepageMain;

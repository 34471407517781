import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

function StaticLayout({ children }) {
    return (
      <div className="gradient-color text-pages">
        <Header />
        {children}
        <Footer />
      </div>
    );
}

export default StaticLayout;

import React, { useState, useEffect } from "react";
import { AppContext } from "./appContext";
import API from "../libs/axios";

export default function ({ children }) {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const prevAuth = window.localStorage.getItem("token") || "";
  const prevUser = JSON.parse(window.localStorage.getItem("user")) || null;
  const prevRedirect = window.localStorage.getItem("redirect") || null;
  const id = window.localStorage.getItem("id") || "";
  const flag = window.localStorage.getItem("flag") || "friend";
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(prevUser);
  const [categories, setCategories] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [token, setToken] = useState(prevAuth);
  const [orderId, setOrderId] = useState(id);
  const [me, setMe] = useState(flag);
  const [redirect, setRedirect] = useState(prevRedirect);

  const onLoad = async () => {
    try {
      setIsLoading(true);
      const cats = await API.get("/categories");
      if (cats) {
        setCategories(cats.categories);
      }

      const { user } = await API.get("/auth/me");
      if (user) {
        setUser(user);
        userHasAuthenticated(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!token) {
      //   window.localStorage.clear();
    } else {
      window.localStorage.setItem("token", token);
    }
    if (!user) localStorage.removeItem("user");
    else window.localStorage.setItem("user", JSON.stringify(user));
    if (!orderId) localStorage.removeItem("id");
    else window.localStorage.setItem("id", orderId);
    if (!me) localStorage.removeItem("flag");
    else localStorage.setItem("flag", me);
    if (!redirect) localStorage.removeItem("redirect");
    else localStorage.setItem("redirect", redirect);
  }, [user, token, orderId, me, redirect]);

  useEffect(() => {
    if (window?.location?.pathname.includes('/privacy-policy')) return;
    onLoad();
  }, []);

  useEffect(() => {
    if (window?.location?.pathname.includes('/privacy-policy')) return;
    onLoad();
  }, [window?.location?.pathname]);

  return (
    <AppContext.Provider
      value={{
        isAuthenticated,
        userHasAuthenticated,
        isLoading,
        setIsLoading,
        user,
        setUser,
        categories,
        setCategories,
        token,
        setToken,
        orderId,
        setOrderId,
        me,
        setMe,
        redirect,
        setRedirect,
        contacts,
        setContacts
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

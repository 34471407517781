import React, { useEffect } from "react";
import ConfettiGenerator from "confetti-js";
import gift1 from "../../images/confetti/1.svg";
import gift2 from "../../images/confetti/2.svg";
import gift3 from "../../images/confetti/3.svg";
import gift4 from "../../images/confetti/4.svg";
import gift5 from "../../images/confetti/5.svg";
import gift6 from "../../images/confetti/6.svg";
import gift7 from "../../images/confetti/7.svg";
import gift8 from "../../images/confetti/8.svg";
import gift9 from "../../images/confetti/9.svg";
import gift10 from "../../images/confetti/10.svg";
import gift11 from "../../images/confetti/11.svg";
import gift12 from "../../images/confetti/12.svg";
import gift13 from "../../images/confetti/13.svg";
import gift14 from "../../images/confetti/14.svg";
import gift15 from "../../images/confetti/15.svg";
import gift16 from "../../images/confetti/16.svg";
import gift17 from "../../images/confetti/17.svg";
import gift18 from "../../images/confetti/18.svg";
import gift19 from "../../images/confetti/19.svg";
import gift20 from "../../images/confetti/20.svg";
import gift21 from "../../images/confetti/21.svg";
import gift22 from "../../images/confetti/22.svg";
import gift23 from "../../images/confetti/23.svg";
import gift24 from "../../images/confetti/24.svg";
import gift25 from "../../images/confetti/25.svg";
import gift26 from "../../images/confetti/26.svg";
import gift27 from "../../images/confetti/27.svg";

const Confetti = ({ receiver }) => {
  useEffect(() => {
    (async () => {
      const confettiSettings = {
        target: "my-canvas",
        size: 1.5,
        clock: 10,
        max: 20,
        colors: [[0, 0, 255]],
        props: [
          { type: "svg", src: gift1 },
          { type: "svg", src: gift2 },
          { type: "svg", src: gift3 },
          { type: "svg", src: gift4 },
          { type: "svg", src: gift5 },
          { type: "svg", src: gift6 },
          { type: "svg", src: gift7 },
          { type: "svg", src: gift8 },
          { type: "svg", src: gift9 },
          { type: "svg", src: gift10 },
          { type: "svg", src: gift11 },
          { type: "svg", src: gift12 },
          { type: "svg", src: gift13 },
          { type: "svg", src: gift14 },
          { type: "svg", src: gift15 },
          { type: "svg", src: gift16 },
          { type: "svg", src: gift17 },
          { type: "svg", src: gift18 },
          { type: "svg", src: gift19 },
          { type: "svg", src: gift20 },
          { type: "svg", src: gift21 },
          { type: "svg", src: gift22 },
          { type: "svg", src: gift23 },
          { type: "svg", src: gift24 },
          { type: "svg", src: gift25 },
          { type: "svg", src: gift26 },
          { type: "svg", src: gift27 },
        ],
      };

      const confetti = new ConfettiGenerator(confettiSettings);
      confetti.render();

      return () => confetti.clear();
    })();
  }, []);
  if (receiver) {
    return <canvas id="my-canvas" style={{ zIndex: 10 }}></canvas>;
  } else {
    return <canvas id="my-canvas"></canvas>;
  }
};

export default Confetti;

import React from "react";
import ReceiverHomepageMain from "../../components/ReceiverHomepageMain/ReceiverHomepageMain";

const ReceiverHomepage = () => {
  return (
    <>
      <ReceiverHomepageMain />
    </>
  );
};

export default ReceiverHomepage;

import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import API from "../../libs/axios";
import Loader from "../Loader/Loader";

const ReceiverVideoMainContent = () => {
  const history = useHistory();
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);

  useEffect(async () => {
    if (orderId) {
      getOrder();
    }
  }, []);

  const getOrder = async () => {
    const order = await API.get(`orders/${orderId}`);
    if (order) {
      setOrder(order);
    }
  };

  return (
    <>
      <main className="main-content">
        <div className="container" style={{ height: "100%" }}>
          <div className="card-content-wrapper">
            <div className="card-item mt-20 mb-20">
              <div className="reciever-content-wrapper">
                {!order ? (
                  <Loader />
                ) : (
                  <div className="card-holder">
                    <h2 className="mb-10">
                      {order?.sender.firstName}’s message
                    </h2>
                    {order?.message && (
                      <div className="bg-color-grey text-box">
                        <p>{order.message}</p>
                      </div>
                    )}
                    {order?.video && (
                      <div className="bg-color-grey text-box">
                        <div className="video-wrapper">
                          <div className="video-banner">
                            {/* <a href="#" className="action-btn">
                            <i className="fa fa-play"></i>
                          </a> */}
                            <video
                              controls
                              className="title-info"
                              src={order.video}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="btn-section justify-content-end">
                      <button
                        className="btn btn-rounded btn-primary"
                        onClick={() =>
                          history.push(`/receiver-accept-gc/${orderId}`)
                        }
                      >
                        View Gift card
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ReceiverVideoMainContent;

import React, { useEffect } from "react";
import { useAppContext } from "../../store/appContext";
import { useFormik } from "formik";
import Input from "../Input/Input";
import API from "../../libs/axios";
import { onError } from "../../libs/errorLib";
import LoaderButton from "../LoaderButton/LoaderButton";
import GoBack from "../GoBack/GoBack";
import { toaster } from "../../libs/utils";
// import { Row, Col } from "antd";
import { Link } from "react-router-dom";

const ProfileMainContent = () => {
  const {
    isLoading,
    setIsLoading,
    user,
    setUser,
    userHasAuthenticated,
  } = useAppContext();

  const getUser = async () => {
    try {
      setIsLoading(true);
      const { user } = await API.get("/auth/me");
      if (user) {
        setUser(user);
        userHasAuthenticated(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: user?.email,
      funds: user?.tango?.currentBalance,
      firstname: user?.firstname ?? "",
      lastname: user?.lastname ?? "",
    },
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        values.id = user?.id;
        delete values.funds;
        const payload = { ...values };
        await API.put("users/updateProfile", payload);
        toaster("success", "Profile Updated");
      } catch (e) {
        onError(e);
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    getUser();
  }, []);
  return (
    <main className="main-content">
      <div className="container">
        <div className="pt-60 pt-30-sm pb-60 pb-30-sm">
          <div className="card-content-wrapper">
            <div className="card-item">
              <div className="card-block-wrapper max-w-370 mb-20">
                <form noValidate onSubmit={formik.handleSubmit}>
                  <div className="card-block-title pt-40 mb-30 ml-0 mr-0">
                    <h1>Update Profile</h1>
                  </div>
                  <div className="form-wrapper">
                    <div className="form-group mb-20">
                      <label className="title-info">Email</label>
                      <div className="input-item">
                        <Input
                          id="email"
                          label="Email"
                          type="text"
                          readonly="readonly"
                          name="email"
                          formik={formik}
                          {...formik.getFieldProps("email")}
                        />
                      </div>
                    </div>

                    <div className="form-group mb-20">
                      <div>
                      <label className="title-info">Current Balance</label>

                        <Link to='/funds' className="profile-load-funds button">
                          Add Funds
                          </Link>
                       
                      </div>
                      <div className="input-item">
                        <Input
                          id="funds"
                          label="Current Balance"
                          type="text"
                          readonly="readonly"
                          formik={formik}
                          {...formik.getFieldProps("funds")}
                        />
                      </div>
                    </div>

                    <div className="form-group mb-20">
                      <label className="title-info">First Name</label>
                      <div className="input-item">
                        <Input
                          id="firstname"
                          label="First Name"
                          type="text"
                          name="firstname"
                          formik={formik}
                          {...formik.getFieldProps("firstname")}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-20">
                      <label className="title-info">Last Name</label>
                      <div className="input-item">
                        <Input
                          id="lastname"
                          label="Last Name"
                          type="text"
                          name="lastname"
                          formik={formik}
                          {...formik.getFieldProps("lastname")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="btn-section align-items-center">
                    <GoBack />
                    <LoaderButton
                      className="btn btn-primary"
                      type="submit"
                      buttonText="Update Profile"
                      loadingText="loading"
                      isLoading={isLoading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProfileMainContent;

import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useAppContext } from "../../store/appContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/Input";
import API from "../../libs/axios";
import { onError } from "../../libs/errorLib";
import LoaderButton from "../LoaderButton/LoaderButton";
import DatePicker from "react-datepicker";
import queryString from "query-string";

const DeliveryMessageMainContent = () => {
  const { orderId } = useParams();
  const history = useHistory();
  const { setIsLoading, isLoading } = useAppContext();
  const [message, setMessage] = useState("");
  const [order, setOrder] = useState(null);
  const [video, setVideo] = useState();
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  let { contactName, contactEmail } = queryString.parse(location.search);

  useEffect(() => {
    if (orderId) {
      getOrder();
    }
  }, []);

  const getOrder = async () => {
    window.scrollTo({ behavior: "smooth", top: 0 });
    const order = await API.get(`orders/${orderId}`);
    if (order) {
      setOrder(order);
      setMessage(order.message);

      if (order && order.deliveryDate)
        setDeliveryDate(new Date(order.deliveryDate));
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      recipient_name:
        order && order !== null ? order.recipient.firstName : contactName || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      recipient_name: Yup.string().required("recipient name is required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const order = await API.put(`orders/${orderId}`, {
          recipient: {
            firstName: values.recipient_name,
            email: contactEmail || undefined,
          },
          deliveryDate: deliveryDate,
          message: message,
          video: video,
        });
        if (order) {
          history.push(`/checkout/${orderId}`);
        }
      } catch (e) {
        onError(e);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const fileChangeHandler = async (event) => {
    setIsLoading(true);
    const data = new FormData();
    data.append("video", event.target.files[0]);
    const file = await API.put(`orders/${orderId}/upload-video`, data);
    order.video = file;

    setVideo(file);
    setOrder(order);
    setIsLoading(false);
  };

  const upload = () => {
    if (isLoading) return;

    document.getElementById("upload").click();
  };

  const removeVideo = () => {
    setVideo(null);
    order.video = null;
    setOrder(order);
  };

  return (
    <main className="main-content">
      {order && (
        <>
          <section className="gift-card-section">
            <div className="container">
              <div className="card-content-wrapper max-w-740 dNm-section">
                <div className="card-item">
                  <div className="delivery-detail-form mt-40">
                    <div className="gc-detail-title mb-30">
                      <h3 className="mb-10">Delivery Details</h3>
                      <p>Enter your friend’s information and message.</p>
                    </div>
                    <form noValidate onSubmit={formik.handleSubmit}>
                      <div className="form-item mb-30 pb-30 b-border">
                        <div className="form-group">
                          <label htmlFor="recipient_name">
                            Recipient’s name
                          </label>
                          <div className="input-item icon-field">
                            <Input
                              id="recipient_name"
                              label="Enter recipient name"
                              type="text"
                              name="recipient_name"
                              formik={formik}
                              {...formik.getFieldProps("recipient_name")}
                            />
                          </div>
                        </div>
                        <div className="btn-section">
                          {/* <a href="#" className="link-item">
                            Send message on mobile
                          </a> */}
                          <Link
                            to={`/upload-contacts/${orderId}`}
                            className="link-item"
                          >
                            Upload contacts
                          </Link>
                        </div>
                      </div>

                      <div className="form-item mb-40">
                        <div className="form-group">
                          <label htmlFor="d-date">Delivery Date</label>
                          <DatePicker
                            id="d-date"
                            disabled={true}
                            selected={deliveryDate}
                            minDate={new Date()}
                            onChange={(date) => setDeliveryDate(date)}
                          />
                        </div>
                      </div>

                      <div className="form-item mb-30">
                        <h4>
                          Write a message <span>(Optional)</span>
                        </h4>
                        <div className="form-group">
                          <textarea
                            className="form-control write-message"
                            id="text"
                            name="text"
                            maxLength="250"
                            placeholder="Your message goes here"
                            rows="5"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                          <div
                            className="text-right info-label-text"
                            id="count_message"
                          >
                            {message?.length || 0} / 250
                          </div>
                        </div>
                      </div>

                      <div className="form-item mb-30">
                        <h4>
                          Upload a video message <span> (Optional)</span>
                        </h4>
                        <div className="form-group">
                          <div className="textarea-item">
                            <textarea
                              className="form-control record-video"
                              id="text"
                              name="text"
                              maxLength="250"
                              placeholder=""
                              rows="5"
                            ></textarea>
                            <div className="btn-wrapper">
                              <i className="fas fa-play" />
                              {order && order.video && (
                                <>
                                  <div className="info-text">
                                    Video Uploaded
                                  </div>
                                  <div
                                    className="info-text danger"
                                    onClick={removeVideo}
                                  >
                                    Remove Video
                                  </div>
                                </>
                              )}
                              {(!order || !order.video) && (
                                <>
                                  <div
                                    htmlFor="upload"
                                    onClick={upload}
                                    className="info-text"
                                  >
                                    Click to upload
                                  </div>
                                  <input
                                    id="upload"
                                    type="file"
                                    className="displayNone"
                                    accept="video/mp4,video/x-m4v,video/*"
                                    onChange={fileChangeHandler}
                                  />
                                </>
                              )}
                            </div>
                            {/* <div className="info-label-text text-right py-1">
                              0/2 minutes
                            </div> */}

                            {/* to here */}
                          </div>
                        </div>
                      </div>

                      <div className="btn-section align-items-center">
                        <div className="btn-link mb-0">
                          <Link
                            to={`/send-gift-card/${orderId}`}
                            className="back-btn"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="13"
                              viewBox="0 0 8 13"
                              fill="none"
                            >
                              <path
                                d="M3.3 6.5L7.3 2.5C7.8 2.1 7.8 1.4 7.3 1 6.9 0.5 6.2 0.5 5.8 1L1 5.8C0.5 6.2 0.5 6.8 1 7.2L5.8 12C6.2 12.5 6.9 12.5 7.3 12 7.8 11.6 7.8 10.9 7.3 10.5L3.3 6.5Z"
                                fill="currentColor"
                              />
                            </svg>
                            <span>Go back</span>
                          </Link>
                        </div>
                        {/* <Link to="/checkout"> */}
                        {/* <button
                        type="submit"
                        className="btn btn-rounded btn-primary"
                      >
                        Go to checkout
                      </button> */}
                        <LoaderButton
                          className="btn btn-primary"
                          type="submit"
                          buttonText="Go to checkout"
                          loadingText="loading"
                          isLoading={isLoading}
                        />
                        {/* </Link> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </main>
  );
};

export default DeliveryMessageMainContent;

import React from "react";
import Loader from "../Loader/Loader";

export default function LoaderButton({
  isLoading,
  buttonText,
  loaderWidth = 20,
  loadingText = "Loading...",
  className = "",
  disabled = false,
  ...props
}) {
  return (
    <button
      className={`LoaderButton ${className}`}
      disabled={disabled || isLoading}
      {...props}
    >
      {!isLoading && buttonText}
      {isLoading && (
        <>
          <Loader /> {buttonText}
        </>
      )}
      {props.children}
    </button>
  );
}
